import styled from "styled-components";

export const PinImageWrapper = styled.div`
    .delete-icon{
        position: absolute;
        top: 2px;
        left: 2px;
    }

    .delete-icon:hover{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
`