import {
  DeleteOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined
} from "@ant-design/icons";
import { Button, Col, Input, Popconfirm, Row, Table, Tooltip } from "antd";
import debounce from 'lodash/debounce';
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AdminDashboard from "../../AdminDashboard/AdminDashboard";
// import { useMediaQuery } from "react-responsive";
// import axios from "axios";
import { inject, observer } from "mobx-react";
// import { apiUrl } from "../../../utils/config";
import { ButtonTable, ContentWrapper, ToolbarWrapper, MainContain, TableContain } from "../../AdminDashboard/style";
import moment from 'moment';

const Model3D = ({ adminStore, userStore, authStore }) => {
  // const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [totalDocs, setTotalDocs] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();

  window.onscroll = () => adminStore.onscrollSticky(window, document, "toolbar-sticky");

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const onChange = e => {
    debounceCalculate(e.target.value)
  }

  const handleChangePagination = (current, pageSize) => {
    adminStore.setCurrentPage(current);
    adminStore.setCurrentLimit(pageSize);
    if (paramSearch && paramSearch !== "") {
      setSearchParams({ page: current, limit: pageSize, search: paramSearch });
    } else {
      setSearchParams({ page: current, limit: pageSize });
    }
  }

  const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
  const limit = searchParams.get("limit") ? parseInt(searchParams.get("limit")) : 10;
  const paramSearch = searchParams.get("search") ? searchParams.get("search") : "";

  adminStore.setSearchInput(paramSearch);

  const debounceCalculate = debounce(function (value) {
    handleSearchUsers(value)
  }, 1000)

  const handleSearchUsers = async e => {
    let search = e && e !== '' ? e.trim() : '';
    if (search && search !== "") {
      setSearchParams({ page: 1, limit: limit, search: search });
    } else {
      setSearchParams({ page: 1, limit: limit })
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const hasSelected = selectedRowKeys.length > 0

  useEffect(() => {
    const fetchData = async () => {
      adminStore.setSelectedKeyMenu('model3ds?page=1&limit=10');
      await adminStore.getAllModel3DS(page, limit, paramSearch)
        .then(response => {
          if (response.docs) {
            adminStore.setModel3DS(response.docs)
            setTotalDocs(response.totalDocs)
          }
        })
        .catch(err => {
          adminStore.setLoadingProgress(false);
        })
    }
    fetchData();
  }, [adminStore, limit, page, paramSearch])

  const handleDeleteModel = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deleteModel3DS(record)
      .then((response) => {
        const data = adminStore.model3DS.filter((x) => x.id !== response.id);
        adminStore.setModel3DS(data);
        adminStore.openNotification('bottomRight', 'success', 'Delete model successfully!')
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!')
      });
  };

  const deleteMultipleModel3DS = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deleteMultipleModel3DS(record)
      .then((response) => {
        const _model = [...adminStore.model3DS];
        for (let i = 0; i < record.length; i++) {
          const index = _model.findIndex((x) => x.id === record[i]);
          if (index >= 0) {
            _model.splice(index, 1);
          }
        }
        setSelectedRowKeys([])
        adminStore.setModel3DS(_model);
        adminStore.openNotification('bottomRight', 'success', 'Delete model successfully!')
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!')
      });
  };

  const toEditPage = () => { }
  const toAddPage = () => {
    return navigate(`/admin/model3ds/new`)
  }

  const columns = [
    {
      title: "Title",
      key: 'title',
      dataIndex: 'title',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.title?.localeCompare(b.title),
      render: (record, obj) => {
        return <span> {
          <Link style={{ color: '#000' }} onClick={toEditPage} to={`/admin/model3ds/${obj.id}`}>
            {record}
          </Link>
        } </span>
      },
    },
    {
      title: 'IonAccess',
      dataIndex: 'ionAccess',
      key: 'ionAccess',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (a.ionAccess || 0) - (b.ionAccess || 0)
      },
      render: (record, obj) => {
        return <span> {record ? record : 0} </span>
      },
    },
    {
      title: "Type",
      dataIndex: 'type',
      key: 'type',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (a.type || 0) - (b.type || 0)
      },
      render: (record, obj) => {
        return <span> {record ? record : 0}</span>
      },
    },
    {
      title: 'SourceType',
      dataIndex: 'sourceType',
      key: 'sourceType',
      sorter: (a, b) => {
        return (a.sourceType || 0) - (b.sourceType || 0)
      },
      render: (record, obj) => {
        return (
          <span >{record ? record : 0}</span>
        )
      },
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a && b && a?.project && b.project && a?.project?.name.localeCompare(b?.project?.name),
      render: (record, obj) => {
        return <span> {record ? record.name : ''} </span>
      },
    },
    {
      title: "Created At",
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (record, obj) => {
        return <span> {moment(record).format('MMMM Do YYYY, h:mm:ss A')} </span>
      },
    },
    {
      title: "Action",
      dataIndex: 'id',
      key: 'event',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: record => {
        return (
          <Row type="flex" justify="center" key="action">
            <Tooltip placement="topLeft" title={'Edit'}>
              <Link onClick={toEditPage} to={`/admin/model3ds/${record}`}>
                <ButtonTable>
                  <EditOutlined />
                </ButtonTable>
              </Link>
            </Tooltip>
            <Tooltip placement="topLeft" title={'Delete'}>
              <Popconfirm
                placement="left"
                title={"Are you sure delete this record?"}
                onConfirm={() => handleDeleteModel(record)}
                okText="Yes"
                cancelText="No"
              >
                <ButtonTable>
                  <DeleteOutlined />
                </ButtonTable>
              </Popconfirm>
            </Tooltip>
          </Row>
        );
      }
    }
  ]

  return (
    <AdminDashboard>
      <ContentWrapper >
        <div id="toolbar-sticky">
          <ToolbarWrapper className="sticky-children">
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                onChange={onChange}
                className="action-search"
                placeholder=" Search..."
                prefix={<SearchOutlined />}
                defaultValue={paramSearch}
                allowClear={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: "end" }}>
              {
                hasSelected &&
                <Button
                  type="danger"
                  className="action-btn"
                  icon={<DeleteOutlined />}
                  style={{ paddingLeft: 17, paddingRight: 17 }}
                  onClick={() => deleteMultipleModel3DS(selectedRowKeys)}
                >
                  DELETE
                </Button>
              }
              <Button
                type="primary"
                className="action-btn"
                icon={<PlusCircleOutlined />}
                style={{ marginLeft: 2 }}
                onClick={toAddPage}
              >
                ADD NEW
              </Button>
            </Col>
          </ToolbarWrapper>
        </div>
        <MainContain >
          <TableContain>
            <Col span={24} >
              <Table
                bordered
                size="default"
                columns={columns}
                dataSource={adminStore.model3DS || []}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                scroll={{ x: 1000 }}
                pagination={{
                  defaultPageSize: limit,
                  showSizeChanger: true,
                  current: page,
                  pageSize: limit,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  locale: { items_per_page: "" },
                  total: totalDocs || 1,
                  onChange: (page, pageSize) =>
                    handleChangePagination(page, pageSize)
                }}
                locale={{ emptyText: "No data" }}
                className="border-space"
              />
            </Col>
          </TableContain>
        </MainContain>
      </ContentWrapper>
    </AdminDashboard>
  );
};

export default inject('userStore', 'adminStore', 'authStore')(observer(Model3D));
