/* eslint-disable no-template-curly-in-string */
import React from 'react'
import {
  Cartesian3,
  Matrix4,
  Matrix3,
  defined,
  Transforms,
  Ellipsoid
} from 'cesium'
export const clickPoint = (viewer, clickPosition) => {
  var scene = viewer.scene;
  //var pickedObject = scene.pick(clickPosition);
  var position;

  if (scene.pickPositionSupported) {
    position = scene.pickPosition(clickPosition);

    return {
      position: position,
      //pickedObject: pickedObject
    }
  } else {
    return false
  }

}
export const getRefPoint = (modelTileset) => {
  var refPoint = new Matrix3();
  Matrix4.getTranslation(modelTileset, refPoint);
  return refPoint;
};
export const distOnLocal = (refPoint, modelCenter, pointGlobal1, pointGlobal2) => {
  var pointPlane1 = cnvGlobalToLocal(refPoint, modelCenter, pointGlobal1);
  var pointPlane2 = cnvGlobalToLocal(refPoint, modelCenter, pointGlobal2);
  var distance = Cartesian3.distance(pointPlane2, pointPlane1);
  return distance;
};

export const cnvGlobalToLocal = (refPoint, modelCenter, pointGlobal) => {
  var pointENU = cnvECEFtoENU(refPoint, pointGlobal);
  pointENU.x += modelCenter.x;
  pointENU.y += modelCenter.y;
  pointENU.z += modelCenter.z;
  return pointENU;
};

export const cnvECEFtoENU = (refPoint, pointECEF) => {
  var pointENU = new Cartesian3();
  var matECEFtoENU = new Matrix4();
  var matENUtoECEF = new Matrix4();
  Transforms.eastNorthUpToFixedFrame(refPoint, Ellipsoid.WGS84, matENUtoECEF);
  Matrix4.inverse(matENUtoECEF, matECEFtoENU);
  Matrix4.multiplyByPoint(matECEFtoENU, pointECEF, pointENU);
  return pointENU;
};