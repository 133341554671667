import  axios from "axios";
import { apiUrl } from "./config";

export const isImage = async (url) => {
  // Make a HEAD request to the URL to check the Content-Type header
  return axios.head(`${apiUrl}/files/${url}`)
    .then((response) => {
      return response.headers['content-type'].startsWith('image/');
    })
    .catch((error) => {
      return false;
    });
  }

export const uploadImage = async (file, token) =>{
    return axios({
        method: `post`,
        url: `${apiUrl}/files/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: file,
      })
}

export const getUploadImage = async (fileName, token) =>{
    return axios({
        method: `get`,
        url: `${apiUrl}/files/${fileName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
}