import React, { useEffect, useRef } from "react";
import { Cartesian3, Color } from "cesium";
import { Viewer, Entity } from "resium";
import { Row } from "antd";
import CesiumNavigation from "cesium-navigation-es6";
import { inject, observer } from "mobx-react";

const Home = (props) => {
  const { uiStore } = props;
  const viewerRef = useRef(null);
  useEffect(() => {
    // navigation compass
    if (viewerRef.current) {
      const options = {};
      options.enableCompass = true;
      options.enableZoomControls = false;
      options.enableDistanceLegend = true;
      options.enableCompassOuterRing = true;
      new CesiumNavigation(viewerRef.current.cesiumElement, options);
    }
    return () => {};
  }, []);

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Viewer
            full
            timeline={false}
            homeButton={false}
            navigationInstructionsInitiallyVisible={false}
            navigationHelpButton={false}
            selectionIndicator={false}
            infoBox={false}
            sceneModePicker={false}
            ref={viewerRef}
          >
            <Entity
              name="Tokyo"
              position={Cartesian3.fromDegrees(139.767052, 35.681167, 100)}
              point={{ pixelSize: 10, color: Color.WHITE }}
              description="hoge"
            />
          </Viewer>
        </Row>
      </div>
    </>
  );
};

export default inject("uiStore")(observer(Home));
