import { CloseOutlined } from "@ant-design/icons";
import { Image, Skeleton } from "antd";
import { IonResource } from "cesium";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";

import { AttributeContainer, CustomModalAttribute } from "./style";
const ModalAtrribute = ({ viewer, projectStore }) => {
  const [isLoading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);

  const clickClose = () => {
    projectStore.setShowModalAtrribute(false);
  };
  const width = document.body.offsetWidth || 0;
  useEffect(() => {
    if (projectStore.showModalAtrribute) {
      const fetchData = async () => {
        setLoading(true);
        // await IonResource.fromAssetId(1335000)
        //   .then(async function (resource) {
            setLoading(false);
            const data = projectStore.showModalAtrribute?.properties || {};
            let result = [];
            for (const property in data) {
              result.push({
                key: property,
                value: data[property],
              });
            }
            setDataTable(result);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setLoading(false);
        //   });
      };
      fetchData();
    }
  }, [projectStore.showModalAtrribute]);
  return (
    <CustomModalAttribute
      width={500}
      className="modalatribute"
      title={projectStore?.showModalAtrribute?.title || 'Properties'}
      open={projectStore.showModalAtrribute}
      onCancel={() => clickClose()}
      maskClosable={false}
      footer={false}
      closeIcon={<CloseOutlined />}
      mask={false}
      destroyOnClose
    >
      <AttributeContainer>
        <Skeleton loading={isLoading} active>
          <table>
            <tbody>
              {dataTable &&
                dataTable.map((element, index) => (
                  <tr key={`${element.key}-${index}`}>
                    <td>{element.key}</td>
                    <td>{element.value}</td>
                    {/* <td>
                      {element.key === "image" ? (
                        <Image
                          width={200}
                          src={element.value}
                          preview={false}
                        />
                      ) :  element.value
                      }
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </Skeleton>
      </AttributeContainer>
    </CustomModalAttribute>
  );
};

export default inject("projectStore")(observer(ModalAtrribute));
