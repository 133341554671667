import { Row, Col, Slider, Checkbox } from 'antd';
import { inject, observer } from "mobx-react";
import { useState } from "react";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { FogContainer, ContainerWrappers } from './style';
const FogControl = ({ projectStore }) => {

    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const handleCancel = () => {
        projectStore.setCurrentToolControl('')
    }
    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${projectStore.viewModelFog?.rgba?.r || 0}, ${projectStore.viewModelFog?.rgba?.g || 0}, ${projectStore.viewModelFog?.rgba?.b || 0}, ${projectStore.viewModelFog?.rgba?.a || 1})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'fixed',
                right: '5px',
                zIndex: '999999',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    const handleChange = (color) => {
        projectStore.setViewModelFog('color', color.hex)
        projectStore.setViewModelFog('alpha', color.rgb.a)
        projectStore.setViewModelFog('rgba', color.rgb)
    };

    const onChangeFog = (key, value) => {
        projectStore.setViewModelFog(key, value)
    }

    return (
        <FogContainer>
            <Row className="close-btn" span={24}>
                <span className='group-button-skybox' onClick={() => handleCancel()}>X</span>
            </Row>
            <ContainerWrappers>
                <Row>
                    <Col>
                        <strong>Fog</strong>
                    </Col>
                </Row>
                <Row className='group-button-skybox'>
                    <Col >
                        Enable: <Checkbox checked={projectStore.viewModelFog?.show} onChange={(e) => onChangeFog('show', e.target.checked)} /> &nbsp;
                    </Col>
                </Row>
                <Row className='group-button-skybox'>
                    <Col span={8}>
                        Color:
                    </Col>
                    <Col span={16}>
                        <div>
                            <div style={styles.swatch} onClick={handleClick}>
                                <div style={styles.color} />
                            </div>
                            {displayColorPicker ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={handleClose} />
                                <SketchPicker color={projectStore.viewModelFog?.rgba || {
                                    r: '0',
                                    g: '0',
                                    b: '0',
                                    a: '1',
                                }} onChange={handleChange} />
                            </div> : null}
                        </div>
                    </Col>
                </Row>
                <Row className='group-button-skybox'>
                    <Col span={8}>
                        Close range:
                    </Col>
                    <Col span={16}>
                        <Slider
                            min={1}
                            max={5000}
                            step={1}
                            onChange={(e) => onChangeFog('fogByDistanceX', e)}
                            value={typeof projectStore.viewModelFog?.fogByDistanceX === 'number' ? projectStore.viewModelFog.fogByDistanceX : 100}
                        />
                    </Col>
                </Row>
                <Row className='group-button-skybox'>
                    <Col span={8}>
                        Long distance:
                    </Col>
                    <Col span={16}>
                        <Slider
                            min={100}
                            max={50000}
                            step={1}
                            onChange={(e) => onChangeFog('fogByDistanceY', e)}
                            value={typeof projectStore.viewModelFog?.fogByDistanceY === 'number' ? projectStore.viewModelFog.fogByDistanceY : 2000}
                        />
                    </Col>
                </Row>
            </ContainerWrappers>
        </FogContainer>
    );
};

export default inject("projectStore")(observer(FogControl));
