import { Col, Row } from "antd";
import { inject, observer } from "mobx-react";
import { useMediaQuery } from "react-responsive";
import { GroupItemContain } from "./style";

function GroupItem({ title, children, adminStore }) {

  return (
    <GroupItemContain >
      <Col className="group-label" span={24}>{title}</Col>
      <Col span={24}>
      <Row gutter={[8,8]} className="group-contain">{children}</Row>
      </Col>
    </GroupItemContain>
  );
}

export default inject("adminStore")(observer(GroupItem));
