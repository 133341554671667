import { Checkbox, Col, Image, Row, Skeleton, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import {
  CloseOutlined,
  GlobalOutlined, MenuUnfoldOutlined, MinusCircleOutlined, PlusCircleOutlined, ShopOutlined
} from "@ant-design/icons";
import { useState } from "react";
import {
  AvatarCustom,
  MainMenuContent,
  MainMenuItem,
  MainMenuItemWrapper,
  MainMenuWrapper,
  MenuItemIcon,
  SubMenuWrapper,
  SubMenuItemWrapper,
  SubMenuItemResponsive,
  SubMenuResponsive
} from "./styled";
import { Cartesian3, Math as CesiumMath } from "cesium";
import { toJS } from "mobx";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';
const Cesium = require('cesium')

const RenderSubSubFolder = ({ projectStore, viewerRef, setShowSubMenu, isShowSubMenu, element, maxHeight, setActiveMenu }) => {

  const searchElement = (data, key, match) => {
    const stack = [];
    data.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.pop();
      if (node[key] === match) {
        return node;
      } else if (node.subcategories) {
        node.subcategories.map(child => stack.push(child))
      } else if (node.subcategorychecklists) {
        node.subcategorychecklists.map(child => stack.push(child))
      }
    }
    return null;
  }

  const handleClickPlaceSubMenu = (element, stt) => {
    if (!viewerRef.current) return
    if (!viewerRef.current.cesiumElement) return
    if (viewerRef.current.cesiumElement) {
      if (!element) return
      const {orientation}  = element ;
      const flyOption = {
        duration: 1,
        destination: Cartesian3.fromDegrees(orientation.lon || 0, orientation.lat || 0, orientation?.height || 100),
        orientation: {
          heading: orientation?.heading || CesiumMath.toRadians(20),
          pitch: orientation?.pitch || CesiumMath.toRadians(-20),
          roll: orientation?.roll || CesiumMath.toRadians(-20)
        },
      };
      const viewer = viewerRef.current.cesiumElement
      viewer.scene.camera.flyTo(flyOption, { duration: 1 });
    }
    const reset = () => {
      projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element?.id));
    }
    if (!stt) {
      setTimeout(reset, 100)
    }
  }

  const handleClickSubMenu = (key, stt) => {
    const node = searchElement(element?.subcategorychecklists, "id", key);
    if (node) {
      if (node.type === "place") {
        handleClickPlaceSubMenu(node);
      }
      else {
        let zoomModel
        projectStore.listModelView.map(item => {
          if (item.key === key) {
            zoomModel = item;
            if (stt) zoomModel.show = true;
          }
        })
        let orientation;
        let destination;
        if (node.orientation) {
          if ((node.orientation?.heading || node.orientation?.heading === 0) && (node.orientation?.pitch || node.orientation?.pitch === 0)) {
            orientation = {
              heading: node.orientation.heading,
              pitch: node.orientation.pitch,
              roll: node.orientation.roll || 0
            }
          }
          if (node.orientation?.lat && node.orientation?.lon) {
            destination = Cesium.Cartesian3.fromDegrees(node.orientation?.lon, node.orientation?.lat, node.orientation.height || 100)
          }
        }
        if (zoomModel) {
          const viewer = viewerRef.current.cesiumElement
          if (zoomModel.modelType === 'geojson' && zoomModel.extent) {
            const rectangle = Cesium.Rectangle.fromDegrees(
              ...zoomModel.extent
            );
            const flyOption = {
              destination: rectangle,
              duration: 0.5,
            }
            if (orientation) {
              flyOption.orientation = orientation;
            }
            if (destination) {
              flyOption.destination = destination;
            }
            viewer.camera.flyTo(flyOption);

          } else {
            const zoomOption = { duration: 0.5 }
            if (zoomModel.modelType === "ion") {
              zoomOption.offset = new Cesium.HeadingPitchRange(
                0.0,
                -0.5,
                zoomModel.boundingSphere.radius * 4.0
              )
            }
            if (orientation) {
              if (zoomModel?.boundingSphere) {
                const cartographic = Cesium.Cartographic.fromCartesian(
                  zoomModel.boundingSphere.center
                );
                const flyOption = {
                  duration: 0.5,
                  destination: Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic.longitude),
                    Cesium.Math.toDegrees(cartographic.latitude),
                    cartographic?.height || 100
                  ),
                  orientation
                };

                if (destination) {
                  flyOption.destination = destination;
                }
                viewer.scene.camera.flyTo(flyOption);
              } else if ((node?.orientation?.lon || node?.orientation?.lon === 0) && (node?.orientation?.lat || node?.orientation?.lat === 0)) {
                const flyOption = {
                  duration: 1,
                  destination: Cartesian3.fromDegrees(node.orientation.lon, node.orientation.lat, node?.orientation?.height || 100),
                  orientation: {
                    heading: orientation.heading || CesiumMath.toRadians(20),
                    pitch: orientation.pitch || CesiumMath.toRadians(-20),
                    roll: orientation.roll || CesiumMath.toRadians(-20)
                  },
                };
                viewer.scene.camera.flyTo(flyOption, { duration: 1 });
              }
            } else {
              viewer.flyTo(zoomModel, zoomOption);
            }
            const reset = () => {
              projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== node?.id));
              zoomModel.show = false
            }
            if (!stt) {
              setTimeout(reset, 100);
            }
          }
        }
      }
    }
  }


  const setTop = () => {
    let topValue = 0;
    for (let i = 0; i < projectStore.activeElement?.subcategories?.length; i++) {
      if (projectStore?.activeElementItem === projectStore.activeElement?.subcategories[i]?.id) {
        topValue = window.innerHeight - (window.innerHeight - (30 + i * 30));
      }
    }
    return 0;
    return topValue;
  }

  const setHeight = () => {
    let max = window.innerHeight - setTop() -  ((element?.subcategorychecklists.length + 2) * 60);
    return max;
  }
  return (
    <>
      <SubMenuWrapper style={setHeight() > 0 ? { top: setTop() } : { }}>
        <Col className="close-btn" span={24}>
          <Tooltip title='Close' placement="right">
            <span onClick={() => {
              setShowSubMenu(!isShowSubMenu)
              setActiveMenu(false)
            }}>
              <CloseOutlined />
            </span>
          </Tooltip>
        </Col>
        <SubMenuItemWrapper maxHeight={setHeight() > 0 ? setHeight() : 300}>
          {
            element?.subcategorychecklists
              .sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)
              .map((sub, subIndex) => {
                return (
                  <MainMenuItemWrapper
                    justify='space-between'
                    key={`${sub.title}-${subIndex}`}
                    onClick={() => {

                    }}
                  >
                    <Col onClick={() => {
                      handleClickSubMenu(sub.id, projectStore.selectedMenus.includes(sub.id))
                    }
                    } span={20} >{sub.title}</Col>
                    <Col>
                      <Checkbox
                        defaultChecked={projectStore.selectedMenus.includes(sub.id)}
                        onChange={(e) => {
                          const pinKey = sub.id + '-pinLocation';
                          let pinModel = projectStore.listPinModelViews.filter(c => c.key.includes(pinKey))
                          const viewer = viewerRef.current.cesiumElement
                          if (viewer && viewer?.scene?.primitives?._primitives?.length > 0) {
                            const _primitives = viewer?.scene?.primitives?._primitives
                            let nodeModel = _primitives.find(c => c.key === sub.id)
                            if (nodeModel) {
                              nodeModel.show = e.target.checked
                            }
                          }
                          if (pinModel.length > 0) {
                            pinModel.map(pinData => {
                              pinData.show = e.target.checked
                              if (pinData?.entityCollection?.values) {
                                pinData?.entityCollection?.values.map(c => {
                                  if (c.key.includes(pinKey)) {
                                    c.billboard.show = e.target.checked
                                  }
                                })
                              }
                            })
                          }
                          if (e.target.checked) {
                            if (!(projectStore.selectedMenus.includes(sub.id))) {
                              projectStore.setSelectedMenus([...projectStore.selectedMenus, sub.id]);
                            }
                          } else {
                            projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== sub.id));
                          }
                        }}
                      />
                    </Col>
                  </MainMenuItemWrapper>
                )
              })
          }
        </SubMenuItemWrapper>
      </SubMenuWrapper>
    </>
  )
}

const getDescription = (element) => {
  const data = element?.properties || {}
  let result = []
  for (const property in data) {
    result.push({
      key: property,
      value: data[property]
    })
  }
  return result
}

const RenderItem = ({ projectStore, viewerRef, setActiveElementItem, element, index, checked, setShowSubMenu, isShowSubMenu, setActiveMenu, activeMenu, handleForgetCZMLAndWalk, maxHeight }) => {
  function startReRender() {
    if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement._cesiumWidget) {
      //if (viewerRef.current.cesiumElement.scene.requestRenderMode) { viewerRef.current.cesiumElement.scene.requestRender(); }
    }
  }
  const searchElement = (data, key, match) => {
    const stack = [];
    data.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.pop();
      if (node[key] === match) {
        return node;
      } else if (node.subcategories) {
        node.subcategories.map(child => stack.push(child))
      } else if (node.subcategorychecklists) {
        node.subcategorychecklists.map(child => stack.push(child))
      }
    }
    return null;
  }

  const handleClickPlaceMenu = (element, stt) => {
    if (!viewerRef.current) return
    if (!viewerRef.current.cesiumElement) return
    if (viewerRef.current.cesiumElement) {
      if (!element) return
      // if (element.subcategorychecklists && element.subcategorychecklists.length > 0) return
      const flyOption = {
        duration: 1,
        destination: Cartesian3.fromDegrees(element?.orientation?.lon, element?.orientation?.lat, element?.orientation?.height || 100),
        orientation: {
          heading: element?.orientation?.heading || CesiumMath.toRadians(20),
          pitch: element?.orientation?.pitch || CesiumMath.toRadians(-20),
          roll: element?.orientation?.roll || CesiumMath.toRadians(-20)
        },
      };
      const viewer = viewerRef.current.cesiumElement
      viewer.scene.camera.flyTo(flyOption, { duration: 1 });
      startReRender()
      const reset = () => {
        setActiveElementItem(false)
        projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element.id));
        startReRender()
      }
      if (!stt) {
        setTimeout(reset, 100);
      }
    }
  }

  const handleClickMenu = (key, stt) => {
    const node = searchElement(projectStore.projectDetail?.categories, "id", key)
    if (node) {
      if (node.type === 'place') {
        handleClickPlaceMenu(node, stt)
      } else {
        let zoomModel
        projectStore.listModelView.map(item => {
          if (item.key === key) {
            zoomModel = item;
            if (stt) zoomModel.show = true;
          }
        })
        let orientation;
        let destination
        if (node.orientation) {
          if (node.orientation.heading && node.orientation.pitch) {
            orientation = {
              heading: node.orientation.heading,
              pitch: node.orientation.pitch,
              roll: node.orientation.roll || 0
            }
          }
          if (node.orientation.lat && node.orientation.lon && node.orientation.height) {
            destination = Cesium.Cartesian3.fromDegrees(node.orientation.lon, node.orientation.lat, node.orientation.height)
          }
        }
        if (zoomModel) {
          const viewer = viewerRef.current.cesiumElement

          if (zoomModel.modelType === 'geojson' && zoomModel.extent) {
            const rectangle = Cesium.Rectangle.fromDegrees(
              ...zoomModel.extent
            );
            const flyOption = {
              destination: rectangle,
              duration: 0.5,
            }
            if (orientation) {
              flyOption.orientation = orientation;
            }
            if (destination) {
              flyOption.destination = destination;
            }
            viewer.camera.flyTo(flyOption);

          } else {
            const zoomOption = { duration: 0.5 }
            if (zoomModel.modelType === "ion") {
              zoomOption.offset = new Cesium.HeadingPitchRange(
                0.0,
                -0.5,
                zoomModel.boundingSphere.radius * 4.0
              )
            }
            if (orientation) {
              if (zoomModel?.boundingSphere) {
                const cartographic = Cesium.Cartographic.fromCartesian(
                  zoomModel.boundingSphere.center
                );
                const flyOption = {
                  duration: 0.5,
                  destination: Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic.longitude),
                    Cesium.Math.toDegrees(cartographic.latitude),
                    cartographic?.height || 100
                  ),
                  orientation
                };

                if (destination) {
                  flyOption.destination = destination;
                }
                viewer.scene.camera.flyTo(flyOption);
              } else if (node.orientation.lon && node.orientation.lat) {
                const flyOption = {
                  duration: 1,
                  destination: Cartesian3.fromDegrees(node?.orientation?.lon, node?.orientation?.lat, node?.orientation?.height || 100),
                  orientation: {
                    heading: orientation.heading || CesiumMath.toRadians(20),
                    pitch: orientation.pitch || CesiumMath.toRadians(-20),
                    roll: orientation.roll || CesiumMath.toRadians(-20)
                  },
                };
                viewer.scene.camera.flyTo(flyOption, { duration: 1 });
              }
            } else {
              viewer.flyTo(zoomModel, zoomOption);
            }
            // if (orientation) {
            //   viewer.camera.setView({ orientation });
            // }
            // zoomModel.show = true
            const reset = () => {
              setActiveElementItem(false)
              projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element.id));
              zoomModel.show = false
              startReRender()
            }
            if (!stt) {
              setTimeout(reset, 100);
            }
          }
        }
      }
    }
  }

  return (
    <>
      <MainMenuItemWrapper
        justify='space-between'
        key={`${element.title}-${index}`}
        onClick={() => {
          if (element.subcategorychecklists && element.subcategorychecklists.length > 0) {
            setActiveElementItem(element.id)
          } else {
            if (activeMenu) {
              if (activeMenu === element.id) {
                // setActiveMenu(false)
                // projectStore.setRightData([])
              } else {
                // setActiveMenu(element.id)
              }
            } else {
              // setActiveMenu(element.id)
            }
          }
          handleForgetCZMLAndWalk()
        }
        }
        active={activeMenu === element.id ? 1 : 0}
      >
        <Col
          onClick={() => {
            if (!element.subcategorychecklists || element.subcategorychecklists.length === 0) {
              // projectStore.setRightData(getDescription(element))
            }
            handleClickMenu(element.id, projectStore.selectedMenus.includes(element.id))
          }}
          span={18} style={{ wordBreak: "break-word" }}>{element.title}
        </Col>
        <Col style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
          {
            element.subcategorychecklists && element.subcategorychecklists.length > 0 ? (
              <>
                <Checkbox
                  style={{ paddingRight: '3px' }}
                  onClick={() => {
                    setActiveElementItem(element.id)
                    setActiveMenu(false)
                  }}
                  checked={projectStore.selectedMenus.includes(element.id)}
                  onChange={(e) => {
                    setShowSubMenu(false);
                    const pinKey = element.id + '-pinLocation';
                    let pinModel = projectStore.listPinModelViews.filter(c => c.key.includes(pinKey))
                    const viewer = viewerRef.current.cesiumElement
                    if (viewer && viewer?.scene?.primitives?._primitives?.length > 0) {
                      const _primitives = viewer?.scene?.primitives?._primitives
                      let nodeModel = _primitives.find(c => c.key === element.id)
                      if (nodeModel) {
                        nodeModel.show = e.target.checked
                      }
                    }
                    if (pinModel.length > 0) {
                      pinModel.map(pinData => {
                        pinData.show = e.target.checked
                        if (pinData?.entityCollection?.values) {
                          pinData?.entityCollection?.values.map(c => {
                            if (c.key.includes(pinKey)) {
                              c.billboard.show = e.target.checked
                            }
                          })
                        }
                      })
                    }
                    if (e.target.checked) {
                      // projectStore.setRightData(getDescription(element))
                      if (!(projectStore.selectedMenus.includes(element.id))) {
                        projectStore.setSelectedMenus([...projectStore.selectedMenus, element.id]);
                      }
                    } else {
                      setActiveElementItem(false)
                      projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element.id));
                    }
                  }}
                />
                {
                  isShowSubMenu && element.id === checked ?
                    <MinusCircleOutlined onClick={() => {
                      setActiveElementItem(false)
                      setShowSubMenu(false)
                      setActiveMenu(false)
                    }
                    } style={{ fontSize: "16px" }} /> : <PlusCircleOutlined onClick={() => {
                      setShowSubMenu(true)
                      setActiveMenu(element.id)
                    }}
                      style={{ fontSize: "16px" }} />
                }
              </>
            ) : (
              <Checkbox
                onClick={() => {
                  setActiveElementItem(element.id)
                  setActiveMenu(false)
                }}
                checked={projectStore.selectedMenus.includes(element.id)}
                onChange={(e) => {
                  setShowSubMenu(false);
                  const pinKey = element.id + '-pinLocation';
                  let pinModel = projectStore.listPinModelViews.filter(c => c.key.includes(pinKey))
                  const viewer = viewerRef.current.cesiumElement
                  if (viewer && viewer?.scene?.primitives?._primitives?.length > 0) {
                    const _primitives = viewer?.scene?.primitives?._primitives
                    let nodeModel = _primitives.find(c => c.key === element.id)
                    if (nodeModel) {
                      nodeModel.show = e.target.checked
                    }
                  }
                  if (pinModel.length > 0) {
                    pinModel.map(pinData => {
                      pinData.show = e.target.checked
                      if (pinData?.entityCollection?.values) {
                        pinData?.entityCollection?.values.map(c => {
                          if (c.key.includes(pinKey)) {
                            c.billboard.show = e.target.checked
                          }
                        })
                      }
                    })
                  }
                  if (e.target.checked) {
                    // projectStore.setRightData(getDescription(element))
                    if (!(projectStore.selectedMenus.includes(element.id))) {
                      projectStore.setSelectedMenus([...projectStore.selectedMenus, element.id]);
                    }
                  } else {
                    // projectStore.setRightData([])
                    setActiveElementItem(false)
                    projectStore.setSelectedMenus(projectStore.selectedMenus.filter(x => x !== element.id));
                  }
                }}
              />
            )
          }
        </Col>
      </MainMenuItemWrapper>
      {isShowSubMenu && element.subcategorychecklists && element.subcategorychecklists?.length > 0 &&
        projectStore.activeElementItem === element.id && (
          <RenderSubSubFolder
            setShowSubMenu={setShowSubMenu}
            isShowSubMenu={isShowSubMenu}
            element={element}
            viewerRef={viewerRef}
            projectStore={projectStore}
            maxHeight={maxHeight}
            setActiveMenu={setActiveMenu}
          ></RenderSubSubFolder>
        )
      }
    </>
  )
}

const MenuIcon = ({
  title,
  icon,
  actived,
  element,
  projectStore,
  handleForgetCZMLAndWalk,
  handleClickCategory,
  menuLength,
  activeCategory,
  setActiveCategory,
  setShowMenu,
  isShowMenu
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  return (
    <Tooltip placement="right" title={title} zIndex={9999} overlayStyle={{ display: isMobile ? "block" : "none" }} destroyTooltipOnHide={true}>
      <MenuItemIcon
        actived={actived}
        span={menuLength && menuLength > 1 && !isMobile ? 12 : 24}
        onClick={() => {
          setShowMenu(!isShowMenu)
          projectStore.setActiveElement(element)
          projectStore.setRightData([])
          handleForgetCZMLAndWalk()
          handleClickCategory(element, activeCategory ? (activeCategory && activeCategory.id === element.id ? false : element) : element, activeCategory.id === element.id ? activeCategory : false)
          setActiveCategory(activeCategory ? (activeCategory && activeCategory.id === element.id ? false : element) : element)
        }}
      >
        <Row className="title-icon" style={isMobile ? {} : {display: 'none'}}>
          {icon}
        </Row>
        <Row className="title-row">
          <span className="menu-title">{title}</span>
        </Row>
      </MenuItemIcon>
    </Tooltip>
  );
};

const MainMenu = ({ projectStore, viewerRef, subCategories, setSubCategories }) => {
  const projectDetail = projectStore.projectDetail;
  const [isShowSubMenu, setShowSubMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [isShowMenu, setShowMenu] = useState(false);
  const [activeCategory, setActiveCategory] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  const styleIcon = { fontSize: "30px", margin: "10px" }

  const removeCZML = (add) => {
    if (!viewerRef?.current?.cesiumElement) return;
    const viewer = viewerRef.current.cesiumElement;
    viewer.clock.shouldAnimate = false;
    viewer.trackedEntity = undefined;
    if (viewer.clock.onTick?._listeners?.filter((x) => x?.name == add)) {
      viewer.clock.onTick._listeners = viewer.clock.onTick?._listeners?.filter(
        (x) => x?.name != add
      );
    }
  };

  const handleForgetCZMLAndWalk = () => {
    if (projectStore.volautoMode) {
      removeCZML("onVolauto");
      projectStore.setAssignVolautoMode("startCZML", false);
      projectStore.setAssignVolautoMode("pauseCZML", false);
      projectStore.setVolautoMode(false);
    }
    if (projectStore.flyWalkMode) {
      projectStore.setViewMode("Default mode");
      projectStore.setFlyWalkMode(false);
    }
  };

  const handleClickCategory = (payload, isShowPin) => {
    setSubCategories(projectStore.activeElement?.subcategories.sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order))
    const viewer = viewerRef.current.cesiumElement
    if (payload && payload.zoomTo) {
      if (!viewerRef.current) return
      if (!viewerRef.current.cesiumElement) return
      let zoomModel
      projectStore.visibleModels.map(item => {
        if (item?.id === payload.zoomTo) {
          zoomModel = projectStore.listModelView?.find(x => x?.key === item.id);
        }
      })
      if (zoomModel) {
        if (zoomModel.modelType === 'geojson' && zoomModel.extent) {
          const rectangle = Cesium.Rectangle.fromDegrees(
            ...zoomModel.extent
          );
          viewer.camera.flyTo({
            destination: rectangle,
          });
        }
        else {
          viewer.flyTo(zoomModel, {
            duration: 0.5
          });
        }
      }
    }
    if ((payload.lat || payload.lat === 0) && (payload.long || payload.long === 0)) {
      const flyOption = {
        duration: 1,
        destination: Cartesian3.fromDegrees(payload?.long, payload?.lat, payload?.height || 100),
        orientation: {
          heading: payload?.heading || CesiumMath.toRadians(20),
          pitch: payload?.pitch || CesiumMath.toRadians(-20),
          roll: payload?.roll || CesiumMath.toRadians(-20)
        },
      };
      viewer.scene.camera.flyTo(flyOption, { duration: 1 });
    }
    const pinKey = payload.id + '-pinLocation';
    let pinModel = projectStore.listPinModelViews.filter(c => c.key.includes(pinKey))
    if (viewer && viewer?.scene?.primitives?._primitives?.length > 0) {
      const _primitives = viewer?.scene?.primitives?._primitives
      let nodeModel = _primitives.find(c => c.key === payload.id)
      if (nodeModel) {
        nodeModel.show = isShowPin ? true : false
      }
    }
    if (pinModel.length > 0) {
      pinModel.map((pinData) => {
        pinData.show = isShowPin ? true : false
        if (pinData?.entityCollection?.values) {
          pinData?.entityCollection?.values.map(c => {
            if (c.key.includes(pinKey)) {
              c.billboard.show = isShowPin ? true : false
            }
          })
        }
      })
    }
  }

  const renderIcon = (key) => {
    let entities
    switch (key) {
      case "GlobalOutlined":
        entities = <GlobalOutlined style={styleIcon} />
        break;
      case "ShopOutlined":
        entities = <ShopOutlined style={styleIcon} />
        break;
      default:
        entities = <GlobalOutlined style={styleIcon} />
        break;
    }
    return entities
  }

  const setTop = () => {
    let valueTop = 0;
    for (let i = 0; i < projectDetail?.categories?.length; i++) {
      if (projectStore?.activeElement?.id === projectDetail?.categories[i]?.id) {
        valueTop = window.innerHeight - (25 + i * 70);
      }
    }
    return valueTop;
  }

  const setHeight = () => {
    let maxHeight = 0;
    for (let i = 0; i < projectDetail?.categories?.length; i++) {
      if (projectStore?.activeElement?.id === projectDetail?.categories[i]?.id) {
        maxHeight = window.innerHeight - (25 + i * 60 + 30);
      }
    }
    return maxHeight;
  }

  return (
    <MainMenuWrapper open={projectStore.showMainMenu ? 1 : 0}>
      {
        projectStore.showMainMenu ? (
          <>
            <Row align="middle" justify="center">
              <Skeleton loading={projectStore.isLoading} active>
                <div className="menu-close-btn">
                  <Tooltip title='Close' placement="right">
                    <div onClick={() => projectStore.setShowMainMenu(false)} className="close-btn"><CloseOutlined /></div>
                  </Tooltip>
                </div>
                <MainMenuItem span={24}>
                  {
                    projectDetail?.thumnail && (
                      <Image preview={false} src={projectDetail?.thumnail} width={280} height={130} alt="logo" />
                    )
                  }
                </MainMenuItem>
              </Skeleton>
              {
                projectDetail && projectDetail.categories && projectDetail?.categories?.length > 0 && (
                  <>
                    <Col span={24} className="category-icons">
                      <Row>
                        {
                          projectStore.categories.map((element) => (
                              <MenuIcon
                                key={element.title}
                                projectStore={projectStore}
                                element={element}
                                title={element.title}
                                actived={projectStore?.activeElement?.id === element.id ? 1 : 0}
                                icon={renderIcon(element.icon)}
                                viewerRef={viewerRef}
                                handleForgetCZMLAndWalk={handleForgetCZMLAndWalk}
                                handleClickCategory={handleClickCategory}
                                activeCategory={activeCategory}
                                setActiveCategory={(e) => setActiveCategory(e)}
                                menuLength={projectDetail?.categories?.length}
                                setShowMenu={setShowMenu}
                                isShowMenu={isShowMenu}
                              />
                          ))
                        }
                      </Row>
                    </Col>
                    <Col span={24}>
                      <SubMenuResponsive style={{ top: -setTop() }}>
                        {
                          isShowMenu && subCategories &&
                          subCategories?.length > 0 &&
                          <Col className="btn-close" span={24}>
                            <Tooltip title='Close' placement="right">
                              <span onClick={() => { setShowMenu(false) }}><CloseOutlined /></span>
                            </Tooltip>
                          </Col>
                        }
                        <SubMenuItemResponsive style={{ maxHeight: setHeight() }}>
                          {
                            isShowMenu && subCategories && subCategories?.length > 0 &&
                            subCategories.map((element, index) => (
                              <RenderItem projectStore={projectStore}
                                setActiveElementItem={e => projectStore.setActiveElementItem(e)}
                                key={`${element.title}-${index}`}
                                checked={projectStore.activeElementItem}
                                element={element}
                                isShowSubMenu={isShowSubMenu}
                                setShowSubMenu={(data) => { setShowSubMenu(data) }}
                                index={index}
                                viewerRef={viewerRef}
                                setActiveMenu={e => setActiveMenu(e)}
                                activeMenu={activeMenu}
                                ter
                                handleForgetCZMLAndWalk={handleForgetCZMLAndWalk}
                                maxHeight={setHeight}
                              >
                              </RenderItem>
                            ))
                          }
                        </SubMenuItemResponsive>
                      </SubMenuResponsive>
                      {
                        subCategories && subCategories?.length > 0 && (
                          <MainMenuContent>
                            {
                              subCategories.map((element, index) => (
                                <RenderItem projectStore={projectStore}
                                  setActiveElementItem={e => projectStore.setActiveElementItem(e)}
                                  key={`${element.title}-${index}`}
                                  checked={projectStore.activeElementItem}
                                  element={element}
                                  isShowSubMenu={isShowSubMenu}
                                  setShowSubMenu={(data) => { setShowSubMenu(data) }}
                                  index={index}
                                  viewerRef={viewerRef}
                                  setActiveMenu={e => setActiveMenu(e)}
                                  activeMenu={activeMenu}
                                  ter
                                  handleForgetCZMLAndWalk={handleForgetCZMLAndWalk}
                                  maxHeight={setHeight}
                                >
                                </RenderItem>
                              ))
                            }
                          </MainMenuContent>
                        )
                      }
                    </Col>
                  </>
                )
              }
            </Row>
          </>
        ) : (
          <div className="open-menu-btn"
            onClick={() => {
              if (projectDetail) {
                projectStore.setShowMainMenu(true)
              }
            }}
          >
            <Tooltip title={'Menu'} placement="right">
              <AvatarCustom
                shape="square"
                icon={<MenuUnfoldOutlined />}
                onClick={() => { }}
              />
            </Tooltip>
          </div>
        )
      }
    </MainMenuWrapper>
  );
};

export default inject("projectStore")(observer(MainMenu));