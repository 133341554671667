import styled from 'styled-components'

export const ContainerWrapper = styled.div`
    position : absolute;
    bottom : 26px;
    left : 0px;
    right : 0px;
    padding  :4px;
    background-color :#303336 ;
    color : #fff;
    overflow: hidden;
    border: 1px solid #444;
    cursor: pointer;
    .container{
        &-label{
            font-size : 0.8rem;
            font-weight : bold;
        }
        &-content{
            .content-item{
                padding : 5px;
                .item-text{
                    font-weight :600;
                    font-size : 0.7rem;
                }
            }
        }
        .position{
            span{
                padding : 2px;
                font-size : 0.7rem;
            }
            .pos-item{
                padding-left : 5px;
            }
        }
    }
    @media (max-width: 350px) {
        bottom :26px;
        right : 0px;
        left : 0px;
  }
`