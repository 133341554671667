import axios from 'axios'
import { apiUrl } from './config'
// import AuthStore from '../stores/auth.store';
// const token = authStore.token;
const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
const requests = {
  get: (url, header = false, urlstatic = true) => {
    let jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    if (header && jwt) {
      return axios({
        method: `get`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
    }
    if (urlstatic) {
      return axios({
        method: `get`,
        url: `${apiUrl}${url}`,
      })
    }
    return axios({
      method: `get`,
      url: `${url}`,
    })
  },
  post: (url, body, header = false, onUploadProgress) => {
    if (header) {
      if (onUploadProgress) {
        return axios({
          method: `post`,
          url: `${apiUrl}${url}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: body,
          onUploadProgress
        })
      }
      return axios({
        method: `post`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `post`,
      url: `${apiUrl}${url}`,
      data: body,
    })
  },
  delete: (url, body = false) => {
    if (body) {
      return axios({
        method: `delete`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `delete`,
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  put: (url, body, header = false) => {
    if (header) {
      return axios({
        method: `put`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `post`,
      url: `${apiUrl}${url}`,
      data: body,
    })
  },
}

const UserRequest = {
  getCurrentUser: () => {
    return requests.get(`/users/me`, true)
  }
}

const ProjectOldRequest = {
  getDetail: id => {
    return requests.get(`/projects/${id}`, true)
  },
  getAllProjects: () => requests.get(`/projects-v1?page=1&limit=10`, true),
}

const AuthRequest = {
  login: (email, password) =>
    requests.post(`/auth/login`, {
      email: email,
      password: password,
    }),
  register: (values) =>
    requests.post(`/auth/register`, {
      username: values.username,
      email: values.email,
      password: values.password,
      rePassword: values.rePassword
    }),

  forgotPassword: payload => requests.post(`/auth/forgot-password`, payload),
  resetPassword: payload => requests.post(`/auth/reset-password`, payload),
}

const CognitoRequest = {
  login: (email, password) =>
    requests.post(`/auth-cognito/login`, {
      email: email,
      password: password,
    }),
  register: (values) =>
    requests.post(`/auth-cognito/register`, {
      username: values.username,
      email: values.email,
      password: values.password,
      rePassword: values.rePassword
    }),

  forgotPassword: payload => requests.post(`/auth-cognito/forgot-password`, payload),
  resetPassword: payload => requests.post(`/auth-cognito/confirm-password`, payload),
  confirmAccount: payload => requests.post(`/auth-cognito/confirm-user`, payload),
  changePassword: payload => requests.post(`/auth-cognito/change-password`, payload),
}

const CategoryRequest = {
  getAllCategories: (page, limit, search) => requests.get(`/categories?page=${page}&limit=${limit}${search ? "&search=" + search : ''}`, true),
  getCategoryDetails: id => {
    return requests.get(`/categories/${id}`, true)
  },
  createNewCategory: (body) => requests.post(`/categories`, body, true),
  updateCategory: (id, body) => requests.put(`/categories/${id}`, body, true),
  deleteCategory: (id) => requests.delete(`/categories/${id}`, true),
  deleteMultiple: (ids) => requests.delete(`/categories/del-multiple`, { ids }, true)
}

const SubCategoryRequest = {
  getAllSubCategorys: (page, limit, search) => requests.get(`/sub-categories?page=${page}&limit=${limit}${search ? "&search=" + search : ''}`, true),
  getSubCategoryById: (id) => requests.get(`/sub-categories/${id}`, true),
  create: (body) => requests.post(`/sub-categories`, body, true),
  update: (id, body) => requests.put(`/sub-categories/${id}`, body, true),
  delete: (id) => requests.delete(`/sub-categories/${id}`, true),
  deleteMultiple: (ids) => requests.delete(`/sub-categories/del-multiple`, { ids }, true)
}

const ProjectRequests = {
  getAllProjects: (page, limit, search) => requests.get(`/projects?page=${page}&limit=${limit}${search ? "&search=" + search : ''}`, true),
  getProjectById: (id) => requests.get(`/projects/${id}`, true),
  create: (body) => requests.post(`/projects`, body, true),
  update: (id, body) => requests.put(`/projects/${id}`, body, true),
  delete: (id) => requests.delete(`/projects/${id}`, true),
  deleteMultiple: (ids) => requests.delete(`/projects/del-multiple`, { ids }, true),
  getProjectDefault: () => requests.get(`/projects/project-default`, true)
}

const Model3DRequests = {
  getAllModel3DS: (page, limit, search) => requests.get(`/model-3d?page=${page}&limit=${limit}${search ? "&search=" + search : ''}`, true),
  getModelById: (id) => requests.get(`/model-3d/${id}`, true),
  create: (body) => requests.post(`/model-3d`, body, true),
  update: (id, body) => requests.put(`/model-3d/${id}`, body, true),
  delete: (id) => requests.delete(`/model-3d/${id}`, true),
  deleteMultiple: (ids) => requests.delete(`/model-3d/del-multiple`, { ids }, true)
}

const PointOfInterestRequests = {
  getAllPointOfInterest3DS: (page, limit, search) => requests.get(`/sub-category-checklist?page=${page}&limit=${limit}${search ? "&search=" + search : ''}`, true),
  getPointOfInterestById: (id) => requests.get(`/sub-category-checklist/${id}`, true),
  create: (body) => requests.post(`/sub-category-checklist`, body, true),
  update: (id, body) => requests.put(`/sub-category-checklist/${id}`, body, true),
  delete: (id) => requests.delete(`/sub-category-checklist/${id}`, true),
  deleteMultiple: (ids) => requests.delete(`/sub-category-checklist/del-multiple`, { ids }, true)
}

const PinRequests = {
  getAllPins: (page, limit, search) => requests.get(`/pin?page=${page}&limit=${limit}${search ? "&search=" + search : ''}`, true),
  getPinById: (id) => requests.get(`/pin/${id}`, true),
  create: (body) => requests.post(`/pin`, body, true),
  update: (id, body) => requests.put(`/pin/${id}`, body, true),
  delete: (id) => requests.delete(`/pin/${id}`, true),
  deleteMultiple: (ids) => requests.delete(`/pin/del-multiple`, { ids }, true)
}

export {
  ProjectOldRequest,
  AuthRequest,
  UserRequest,
  CategoryRequest,
  SubCategoryRequest,
  ProjectRequests,
  Model3DRequests,
  PointOfInterestRequests,
  PinRequests,
  CognitoRequest
}
