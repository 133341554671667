import styled from 'styled-components';
import { Modal } from 'antd';

export const CustomModalAttribute = styled(Modal)`
    ${'' /* position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%,-50%); */}
    width : ${props => props.width ? props.width : '500px'};
    .ant-modal-header {
        background : #00acd2;
        color : #fff;
        padding : 5px 25px;
        border-bottom : 1px solid #f0f0f0;
        border-radius : 2px 2px 0 0 ;
        .ant-modal-title{
            margin : 0 ;
            font-size : 16px;
            line-height : 22px;
            word-wrap : break-word;
            text-align : center;
            color : #fff;
            font-weight : 500;
            text-overflow : ellipsis;
            white-space : nowrap;
            overflow: hidden;
            box-sizing : content-box;
        }
    }
    .ant-modal-body{
        padding : 5px 10px;
    }
    .ant-modal-close-x{
        width : 23px;
        height : 23px;
        line-height : 28px;
        color : #fff;
    }
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
    tr:nth-child(even) {
        background-color: #dddddd;
    }
`

export const AttributeContainer = styled.div`
`

