import styled from 'styled-components'
export const RightContainerWrapper = styled.div`
    position : absolute;
    bottom : 50%;
    right : 50px;
    max-width : 500px;
    min-width : 300px;
    height : auto;

    padding  :20px;
    background-color :#59585C ;
    transform : translate(0,50%);
    ${'' /* overflow: hidden; */}
    z-index : 19999;
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    td{
        word-break: break-word;
    }
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
    ${'' /* tr:nth-child(even) {
        background-color: #dddddd;
    } */}
    .top-line{
        &:after{
        content :"";
        width :140px;
        height :4px;
        background-color :#00acd2 ;
        position : absolute;
        left :0px;
        top:0px;
       }
    &:before{
        content :"";
        width :4px;
        height :100px;
        background-color :#00acd2 ;
        position : absolute;
        left :0px;
        top:0px;
       }
    }
    .bottom-line{
    &:after{
        content: "";
        width: 4px;
        height: 100px;
        background-color: #00acd2;
        position: absolute;
        right: 0px;
        bottom: 0px;
       }
    &:before{
        content: "";
        width: 140px;
        height: 4px;
        background-color: #00acd2;
        position: absolute;
        right: 0px;
        bottom: 0px;
       }
    }

    .content{
        color : #fff;
        &-img{
            display : flex;
            justify-content :center;
            align-items : center;
            width :80px;
            height : 80px;
            image{
                object-fit :contain;
            }
        }
        &-text{
            padding: 30px 5px 5px 5px;
            color :#fff;
            font-size : 0.8rem;
            ${'' /* word-break :break-all; */}
        }
    }
    .ant-row{
        margin :0;
    }
   .close-btn{
    position : absolute;
    right : -5px;
    top : -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color : #fff;
    padding : 10px;
    border : 1px solid #fff;
    display : flex;
    justify-content : center;
    align-items : center;
    z-index : 9999;
    cursor : pointer;
    background-color :#00acd2;
   }
`