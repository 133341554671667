import styled from 'styled-components'
export const BloomContainer = styled.div`
  background-color: rgba(222, 222, 222, 0.85);
  position: absolute;
  ${'' /* ${props => !props.takeViewportHeight && `height: calc(100vh - 10px);`}; */}
  z-index : 100;
  top: 5px;
  ${'' /* bottom : 5px; */}
  right: 0px;
  .close-btn {
    display : flex;
    justify-content : flex-end;
    border-bottom: 1px solid #00acd2;
    color : #fff;
    span{
      padding : 5px;
      font-size : 16px;
      font-weight : bold;
      cursor : pointer;
      &:hover{
        color : #00acd2;
      }
    }
  }
  .ant-radio-button-checked {
    border-color :#00acd2;
    background : #00acd2;
  }
  .ant-radio-button-wrapper {
    border-color: #00acd2;
    &:hover{
      background : rgba(0, 172, 210,0.3);
      span {
        color: #fff !important;
      }
    }
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #00acd2;
  }
}
`
export const ContainerWrappers = styled.div`
  padding : 20px 10px ;
  .ant-btn-primary{
    border-color :#00acd2 ;
    background : #00acd2 ;
    &:hover{
        border-color : #00acd2;
      color : #fff;
    }
  }
  table {
    width: 100%;
  }
  .td-input {
    display: flex;
    justify-content: space-between;
    width: 100%
  }
  tr {
    width 100%
  }
  .td-label {
    width: 40%
  }
  .ant-slider {
    width: 60%
  }
  max-height: 80vh;  
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-radius: 5px 0 0 5px;
  &::-webkit-scrollbar { 
    width: 0 !important;
    display: none; 
  }
`
