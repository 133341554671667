import { makeAutoObservable } from 'mobx';
import { CategoryRequest, Model3DRequests, PinRequests, PointOfInterestRequests, ProjectRequests, SubCategoryRequest } from '../utils/requests';
import { message, notification } from 'antd';
import axios from 'axios';
import { cesiumToken } from '../utils/config'

class AdminStore {
    constructor() {
        makeAutoObservable(this);
    }
    listBreadcrumb = [{ path: '/admin', label: 'Home' }]
    isLoading = false
    categoriesData = []
    categoryDetails = {}
    projects = []
    collapsed = false
    currentPage = 1
    currentLimit = 10
    model3DS = []
    pointOfInterestes = []
    selectedKeyMenu = false
    subCategories = []
    searchInput = ""
    pins = []
    openPinModal = false

    setOpenPinModal = (openPinModal) => {
        this.openPinModal = openPinModal
    }

    setSearchInput = (v) => {
        this.searchInput = v
    }

    setPins = data => {
        this.pins = data ? data.reverse() : []
    }
    setSelectedKeyMenu = (key) => {
        this.selectedKeyMenu = key
    }

    setSubCategory = (data) => {
        this.subCategories = data ? data.reverse() : []
    }

    clearPaginationParams = () => {
        this.currentPage = 1
        this.currentLimit = 10
    }

    setCurrentPage = (v) => {
        this.currentPage = v
    }

    setCurrentLimit = (v) => {
        this.currentLimit = v
    }

    setCollapsed = stt => {
        this.collapsed = stt
    }

    setProjects = data => {
        this.projects = data ? data.reverse() : []
    }

    setModel3DS = data => {
        this.model3DS = data
    }

    setPointOfInterestes = data => {
        this.pointOfInterestes = data
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading
    }

    setCategoriesData = data => {
        this.categoriesData = data
    }

    setCategoryDetails = data => {
        this.categoryDetails = data
    }

    setListPathBreadcrumb = (data) => {
        this.listBreadcrumb = data
    }

    onscrollSticky = (window, document, key) => {
        const header = document.getElementById(key);
        if (header) {
            var sticky = header.offsetTop;
            if (!this.collapsed) {
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky-200");
                    header.classList.remove("sticky-80");
                } else {
                    header.classList.remove("sticky-200");
                    header.classList.remove("sticky-80");
                }
            } else {
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky-80");
                    header.classList.remove("sticky-200");
                } else {
                    header.classList.remove("sticky-200");
                    header.classList.remove("sticky-80");
                }
            }
        }
    }

    getAllCategories = (page, limit, search) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            CategoryRequest.getAllCategories(page, limit, search)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    getCategoryDetails = async (id) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            CategoryRequest.getCategoryDetails(id)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    createNewCategory = (body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            CategoryRequest.createNewCategory(body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    message.error("Request failed!");
                })
        })
    }

    updateCategory = (id, body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            CategoryRequest.updateCategory(id, body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    message.error("Request failed!");
                })
        })
    }

    deleteCategory = (categoryId) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            CategoryRequest.deleteCategory(categoryId)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                    message.error("Request failed!");
                })
        })
    }

    deleteMultipleCategories = (categoryIds) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            CategoryRequest.deleteMultiple(categoryIds)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(error => {
                    this.setLoadingProgress(false);
                    reject(error);
                })
        })
    }

    getAllProjects = (page, limit, search) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            ProjectRequests.getAllProjects(page, limit, search)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    createProject = (body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            ProjectRequests.create(body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    getProjectDetail = (id) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            ProjectRequests.getProjectById(id)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    updateProject = (id, body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            ProjectRequests.update(id, body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteProject = (projectId) => {
        return new Promise((resolve, reject) => {
            ProjectRequests.delete(projectId)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteMultipleProject = (projectIds) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            ProjectRequests.deleteMultiple(projectIds)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    openNotification = (placement, method, description) => {
        notification[method]({
            message: `Notification`,
            description: description,
            placement,
        });
    };

    getAllModel3DS = (page, limit, search) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            Model3DRequests.getAllModel3DS(page, limit, search)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteMultipleModel3DS = (modelIds) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            Model3DRequests.deleteMultiple(modelIds)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteModel3DS = (modelId) => {
        return new Promise((resolve, reject) => {
            Model3DRequests.delete(modelId)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    createNewModel3DS = (body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            Model3DRequests.create(body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    this.setLoadingProgress(false);
                    message.error("Request failed!");
                })
        })
    }

    getModelById = async (id) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            Model3DRequests.getModelById(id)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    updateModel3DS = (id, body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            Model3DRequests.update(id, body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    message.error("Request failed!");
                })
        })
    }

    getAllPointOfInterest = (page, limit, search) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PointOfInterestRequests.getAllPointOfInterest3DS(page, limit, search)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteMultiplePointOfInterest = (pointOfInterestIds) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PointOfInterestRequests.deleteMultiple(pointOfInterestIds)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deletePointOfInterest = (pointOfInterestId) => {
        return new Promise((resolve, reject) => {
            PointOfInterestRequests.delete(pointOfInterestId)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    createNewPointOfInterest = (body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PointOfInterestRequests.create(body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    this.setLoadingProgress(false);
                    message.error("Request failed!");
                })
        })
    }

    getPointOfInterestById = async (id) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PointOfInterestRequests.getPointOfInterestById(id)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    updatePointOfInterest = (id, body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PointOfInterestRequests.update(id, body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    message.error("Request failed!");
                })
        })
    }

    getAllSubCategorys = (page, limit, search) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            SubCategoryRequest.getAllSubCategorys(page, limit, search)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    createSubCategory = (body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            SubCategoryRequest.create(body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    getSubCategoryDetail = (id) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            SubCategoryRequest.getSubCategoryById(id)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    updateSubCategory = (subCategoryId, body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            SubCategoryRequest.update(subCategoryId, body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteSubCategory = (subCategoryId) => {
        return new Promise((resolve, reject) => {
            SubCategoryRequest.delete(subCategoryId)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteMultipleSubCategory = (subCategoryIds) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            SubCategoryRequest.deleteMultiple(subCategoryIds)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    getAllPins = (page, limit, search) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PinRequests.getAllPins(page, limit, search)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deleteMultiplePins = (pinIds) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PinRequests.deleteMultiple(pinIds)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    deletePin = (pinId) => {
        return new Promise((resolve, reject) => {
            PinRequests.delete(pinId)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    createNewPin = (body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PinRequests.create(body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    this.setLoadingProgress(false);
                    message.error("Request failed!");
                })
        })
    }

    getPinDetail = async (id) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PinRequests.getPinById(id)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    this.setLoadingProgress(false);
                    reject(err);
                })
        })
    }

    updatePin = (id, body) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            PinRequests.update(id, body)
                .then(res => {
                    this.setLoadingProgress(false);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                    message.error("Request failed!");
                })
        })
    }

    getIonIds = async () => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            axios({
                method: `get`,
                url: 'https://api.cesium.com/v1/assets?idsOnly=true',
                headers: {
                    Authorization: `Bearer ${cesiumToken}`,
                },
            }).then(res => {
                this.setLoadingProgress(false);
                resolve(res.data.items);
            })
            .catch(err => {
                console.log(err);
                this.setLoadingProgress(false);
                reject(err);
            })
        })
    }

}

export default AdminStore;
