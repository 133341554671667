import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SaveOutlined
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Typography
} from "antd";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "../../AdminDashboard/AdminDashboard";
import GroupItem from "../../AdminDashboard/GroupItem";
import { ContentWrapper, ToolbarButton, ToolbarWrapper, TableContain, MainContain, WidgetControl, ContainRelation } from "../../AdminDashboard/style";
import { v1 as uuid } from "uuid";
import TextArea from "antd/lib/input/TextArea";
import ModalAddPin from "../ModalAddPin";
const { Text, } = Typography;
const EditProject = ({ adminStore }) => {
  const [form] = Form.useForm();
  const [addFolderForm] = Form.useForm();
  const [addModel3DForm] = Form.useForm();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [subcategories, setSubCategories] = useState([])
  const [categories, setCategories] = useState([])
  const [model3ds, setModel3ds] = useState([])
  const [allcategories, setAllCategories] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAddModel3d, setOpenAddModel3d] = useState(false);
  const [checked, setChecked] = useState(true);
  const [ionIds, setIonIds] = useState([]);
  const [projectDetail, setProjectDetail] = useState(null);
  const [pins, setPins] = useState([]);

  window.onscroll = () =>
    adminStore.onscrollSticky(window, document, "toolbar-sticky");
  const [icons] = useState([
    {
      value: "GlobalOutlined",
      label: "Global",
    },
    {
      value: "ShopOutlined",
      label: "Shop",
    },
  ]);
  const page = adminStore.currentPage;
  const limit = adminStore.currentLimit;
  const search = adminStore.searchInput;

  const handleCancel = () => {
    setIsModalOpen(false);
    addFolderForm.resetFields()
    setChecked(true)
  };

  const handleCancelAddModel3D = () => {
    setOpenAddModel3d(false);
    addModel3DForm.resetFields()
  };

  const handleOpenAddFolderModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenAddModel3DModal = () => {
    setOpenAddModel3d(true);
  };

  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked)
  };

  const setFolderDetail = (id, value) => {
    let index = allcategories.findIndex(c => c.id === id)
    if (index > -1) {
      let c = [...allcategories]
      c[index].projectId = value
      setAllCategories(c)
    }
  }

  const handleAddFolder = () => {
    addFolderForm.validateFields().then(async values => {
      if (values.useExisting) {
        adminStore.setLoadingProgress(true);
        await adminStore
          .updateCategory(values.folder, {
            projectId: projectId
          })
          .then((res) => {
            let isExist = categories.find(c => c.id === values.folder)
            if (!isExist) {
              setCategories(categories => [
                ...categories,
                {
                  id: res.id,
                  title: res.title,
                  desc: res.desc,
                  order: res.order,
                  createdAt: res.createdAt
                }
              ])
            }
            setFolderDetail(values.folder, projectId)
            adminStore.openNotification(
              "bottomRight",
              "success",
              "Add folder to project successfully!"
            );
            handleCancel()
          })
          .catch((err) => {
            adminStore.setLoadingProgress(false);
            adminStore.openNotification(
              "bottomRight",
              "error",
              "Something went wrong!"
            );
          });
      } else {
        let payload = {
          projectId: projectId,
          icon: values.icon,
          title: values.title,
          order: values?.order < 0 ? 0 : values?.order || 0,
          desc: values.desc,
          lat: parseFloat(values?.lat || 0),
          long: parseFloat(values?.long || 0),
          height: parseFloat(values?.height || 0),
          heading: parseFloat(values?.heading || 0),
          pitch: parseFloat(values?.pitch || 0),
          roll: parseFloat(values?.roll || 0)
        };
        adminStore.setLoadingProgress(true);
        await adminStore
          .createNewCategory(payload)
          .then((res) => {
            setCategories(categories => [
              ...categories,
              {
                id: res.id,
                title: res.title,
                desc: res.desc,
                lat: res.lat,
                long: res.long,
                height: res.height,
                heading: res.heading,
                pitch: res.pitch,
                roll: res.roll,
                order: res.order,
                createdAt: res.createdAt
              }
            ])
            setAllCategories(allcategories => [
              ...allcategories,
              res
            ])
            adminStore.setLoadingProgress(false);
            handleCancel()
            adminStore.openNotification(
              "bottomRight",
              "success",
              "Create new folder successfully!"
            );
          })
          .catch((err) => {
            adminStore.setLoadingProgress(false);
            adminStore.openNotification(
              "bottomRight",
              "error",
              "Something went wrong!"
            );
          });
      }
    })
  };

  const handleAddModel3D = () => {
    addModel3DForm.validateFields().then(async values => {
      const payload = {
        title: values?.title || "",
        desc: values?.desc || "",
        ionAccess: values?.ionAccess || null,
        type: values?.type,
        sourceType: values?.sourceType,
        projectId: projectId,
        height: values?.height,
        fill: {
          color: values?.fillcolor,
          opacity: values?.fillopacity
        },
        stroke: {
          width: values?.strokewidth,
          color: values?.strokecolor,
          opacity: values?.strokeopacity
        }
      };
      adminStore.setLoadingProgress(true);
      await adminStore.createNewModel3DS(payload).then(res => {
        setModel3ds(model3ds => [
          ...model3ds,
          res
        ])
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'success', 'Add new model to project successfully!')
        handleCancelAddModel3D()
      })
        .catch((err) => {
          adminStore.setLoadingProgress(false);
          adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
        });
    })
  };

  const backTo = () => {
    return navigate(`/admin/projects?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`);
  }
  const { Option } = Select;

  const widgets = [
    {
      name: 'Optimize',
      dataIndex: 'optimize',
      key: uuid()
    },
    {
      name: 'Measure',
      dataIndex: 'measure',
      key: uuid()
    },
    {
      name: 'Walk',
      dataIndex: 'walk',
      key: uuid()
    },
    {
      name: 'Shadow',
      dataIndex: 'shadow',
      key: uuid()
    },
    {
      name: 'CZML',
      dataIndex: 'czml',
      key: uuid()
    },
    {
      name: 'Sky Box',
      dataIndex: 'skybox',
      key: uuid()
    },
    {
      name: 'Bloom effect',
      dataIndex: 'bloomeffect',
      key: uuid()
    },
    {
      name: 'Fog',
      dataIndex: 'fog',
      key: uuid()
    },
    {
      name: 'Sun show ',
      dataIndex: 'sunshow',
      key: uuid()
    },
    {
      name: 'FXAA',
      dataIndex: 'fxaa',
      key: uuid()
    },
    {
      name: 'HDR',
      dataIndex: 'hdr',
      key: uuid()
    }
  ]

  const columns = [
    {
      title: "WIDGETS ",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "CONTROL",
      key: "control",
      width: 120,
      render: (record) => (
        <div className="action-show">
          <Form.Item
            name={`widget_${record.dataIndex}`}
            valuePropName="checked"
          >
            <Switch
            />
          </Form.Item>
        </div>
      ),
    },
  ];

  const titleOnClick = () => {
    localStorage.setItem("projectDetail", JSON.stringify(projectDetail));
    localStorage.setItem("project", JSON.stringify(projectDetail))
  }

  const columncategories = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a className="relation-label" href={`/admin/folders/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 80,
      align: "center",
      render: (record, obj) => {
        return <span> {record ? record : 0} </span>
      }
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      align: "center",
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this folder from project?'}
            onConfirm={() => confirmRemoveFolder(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnmodel3ds = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a className="relation-label" href={`/admin/model3ds/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to delete this model3d from project?'}
            onConfirm={() => confirmDeleteModel3D(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const confirmRemoveFolder = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updateCategory(record.id, {
        projectId: null
      })
      .then((res) => {
        setCategories(categories.filter(c => c.id !== record.id))
        setFolderDetail(record.id, null)
        adminStore.openNotification(
          "bottomRight",
          "success",
          "Remove this folder successfully!"
        );
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification(
          "bottomRight",
          "error",
          "Something went wrong!"
        );
      });
  }

  const confirmDeleteModel3D = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deleteModel3DS(record.id)
      .then((response) => {
        setModel3ds(model3ds.filter(c => c.id !== record.id))
        adminStore.openNotification('bottomRight', 'success', 'Delete this model3d successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      adminStore.getAllCategories(1, 1000).then(res => {
        setAllCategories(res.docs)
      })
      adminStore.getIonIds().then(res => {
        setIonIds(res)
      })
      await adminStore.getAllSubCategorys(1, 100).then((response) => {
        setSubCategories(response.docs);
      });
      if (projectId === "new") {
        form.resetFields();
        form.setFieldsValue({
          czmlUrl: "MultipartVehicle_part.czml",
          czmlPath: "https://modelszenata.s3.eu-west-3.amazonaws.com",
        });
      } else {
        await adminStore.getProjectDetail(projectId).then((response) => {
          setProjectDetail(response)
          if (response.categories) {
            let u = response.categories.map(c => {
              return {
                id: c.id,
                title: c.title,
                desc: c.desc,
                createdAt: c.createdAt,
                order: c.order
              }
            })
            setCategories(u)
          }
          if (response.model3ds) {
            setModel3ds(response.model3ds || [])
          }
          if (response.pin) {
            setPins(response.pin || [])
          }
          form.setFieldsValue({
            name: response?.name,
            lat: response?.lat || 0,
            long: response?.long || 0,
            height: response?.height || 0,
            thumnail: response?.thumnail,
            defaultStart: response?.defaultStart,
            czmlUrl: response?.czmlUrl ? response?.czmlUrl : "MultipartVehicle_part.czml",
            czmlPath: response?.czmlPath ? response?.czmlPath : "https://modelszenata.s3.eu-west-3.amazonaws.com",
            token: response?.token
          });
          if (response.startView) {
            const startview = response.startView;
            form.setFieldsValue({
              startlat: startview?.lat || 0,
              startlong: startview?.long || 0,
              startheight: startview?.height || 0,
              startheading: startview?.heading || 0,
              startpitch: startview?.pitch || 0,
              startroll: startview?.roll || 0,
            });
          }
          if (response.widgetsControl) {
            const widgetsControl = response.widgetsControl;
            form.setFieldsValue({
              widget_czml: widgetsControl?.widget_czml,
              widget_measure: widgetsControl?.widget_measure,
              widget_optimize: widgetsControl?.widget_optimize,
              widget_shadow: widgetsControl?.widget_shadow,
              widget_walk: widgetsControl?.widget_walk,
              widget_skybox: widgetsControl?.widget_skybox,
              widget_bloomeffect: widgetsControl?.widget_bloomeffect,
              widget_fog: widgetsControl?.widget_fog,
              widget_sunshow: widgetsControl?.widget_sunshow,
              widget_fxaa: widgetsControl?.widget_fxaa,
              widget_hdr: widgetsControl?.widget_hdr,
            });
          }
        });
      }
    };
    adminStore.setSelectedKeyMenu("projects?page=1&limit=10");
    fetchData();
    return () => {
      form.resetFields();
      setSubCategories([]);
      setCategories([]);
      setAllCategories([])
      setModel3ds([])
      setIonIds([])
      setPins([]);
    };
  }, [projectId]);

  const onFinish = () => {
    form.validateFields().then((values) => {
      const payload = {
        name: values?.name,
        thumnail: values?.thumnail || '',
        lat: values?.lat || 0,
        long: values?.long || 0,
        height: values?.height || 0,
        defaultStart: values?.defaultStart,
        czmlUrl: values?.czmlUrl,
        czmlPath: values?.czmlPath,
        token: values?.token,
        startView: {
          lat: values?.startlat || 0,
          long: values?.startlong || 0,
          height: values?.startheight || 0,
          heading: values?.startheading || 0,
          pitch: values?.startpitch || 0,
          roll: values?.startroll || 0,
        },
        widgetsControl: {
          widget_czml: values?.widget_czml,
          widget_measure: values?.widget_measure,
          widget_optimize: values?.widget_optimize,
          widget_shadow: values?.widget_shadow,
          widget_walk: values?.widget_walk,
          widget_skybox: values?.widget_skybox,
          widget_bloomeffect: values?.widget_bloomeffect,
          widget_fog: values?.widget_fog,
          widget_sunshow: values?.widget_sunshow,
          widget_fxaa: values?.widget_fxaa,
          widget_hdr: values?.widget_hdr,
        }
      };
      if (projectId === "new") {
        return handleCreateProject(payload)
      } else {
        return handleUpdateProject(projectId, payload)
      }
    });
  }

  const handleCreateProject = async (payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .createProject(payload)
      .then((response) => {
        adminStore.setLoadingProgress(false);
        backTo()
        adminStore.openNotification('bottomRight', 'success', 'Create new project successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const handleUpdateProject = async (id, payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updateProject(id, payload)
      .then((response) => {
        adminStore.setLoadingProgress(false);
        // backTo()
        adminStore.openNotification('bottomRight', 'success', 'Update project successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const handleDeleteProject = async () => {
    if (projectId && projectId !== "new") {
      adminStore.setLoadingProgress(true);
      await adminStore
        .deleteProject(projectId)
        .then((response) => {
          adminStore.openNotification('bottomRight', 'success', 'Delete project successfully!');
          backTo()
        })
        .catch((err) => {
          adminStore.setLoadingProgress(false);
          adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
        });
    } else {
      adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
    }
  };


  const Relation = ({ title, children, action, icon, handleClick }) => {
    return (
      <div className="relation">
        <Row style={{ paddingBottom: "5px" }} justify="space-between">
          <div>{title}</div>
          {
            action && projectId !== 'new' && (
              <Button onClick={() => handleClick()}>
                {icon}
                {action}
              </Button>
            )
          }
        </Row>
        {children}
      </div>
    );
  };

  const confirmDeletePin = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore.deletePin(record.id).then((response) => {
      setPins(pins.filter(pin => pin.id !== record.id));
      adminStore.openNotification('bottomRight', 'success', 'Delete pin successfully!');
    })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  }

  const columnPins = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a className="relation-label" href={`/admin/pins/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this record?'}
            onConfirm={() => confirmDeletePin(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleOpenAddPinModal = () => {
    adminStore.setOpenPinModal(true);
  }

  return (
    <AdminDashboard>
      <ContentWrapper>
        <div id="toolbar-sticky">
          {/* <div> */}
          <ToolbarWrapper className="sticky-children">
            <Row justify="end">
              <ToolbarButton
                className="action-btn"
                icon={<CloseCircleOutlined />}
                onClick={backTo}
              >
                CANCEL
              </ToolbarButton>
              {projectId !== "new" && (
                <ToolbarButton
                  type="danger"
                  className="action-btn"
                  icon={<DeleteOutlined />}
                  onClick={handleDeleteProject}
                >
                  DELETE
                </ToolbarButton>
              )}
              <ToolbarButton
                type="primary"
                className="action-btn"
                onClick={onFinish}
                icon={
                  projectId === "new" ? (
                    <PlusCircleOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
              >
                {projectId === "new" ? "CREATE" : "SAVE"}
              </ToolbarButton>
            </Row>
          </ToolbarWrapper>
        </div>
        <MainContain>
          <TableContain>
            <Form
              name="edit-project"
              form={form}
              layout="vertical"
              labelAlign="left"
            >
              <Row>
                <Col xs={24} sm={24} md={12} lg={14} xl={14} xxl={16} >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name={"name"}
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input project name",
                          },
                        ]}
                      >
                        <Input className="form-input" placeholder="Name" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={"thumnail"} label="Thumnail">
                        <Input />
                      </Form.Item>
                    </Col>
                    <GroupItem title={"start view"}>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                        <Form.Item name={"startlat"} label="Lat">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                        <Form.Item name={["startlong"]} label="Long">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                        <Form.Item name={"startheight"} label="Height">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                        <Form.Item name={"startheading"} label="Heading">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                        <Form.Item name={["startpitch"]} label="Pitch">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
                        <Form.Item name={"startroll"} label="Roll">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </GroupItem>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={"lat"} label="Lat">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={["long"]} label="Long">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={"height"} label="Height">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Col span={24}>
                      <Form.Item
                        name={"czmlPath"}
                        label="czml Path"
                        rules={[
                          {
                            required: false,
                            message: "Please input czml Path",
                          },
                        ]}
                      >
                        <Input className="form-input" placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={"czmlUrl"}
                        label="czml Url"
                        rules={[
                          {
                            required: false,
                            message: "Please input czml Url",
                          },
                        ]}
                      >
                        <Input className="form-input" placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={"token"}
                        label="Token"
                        rules={[
                          {
                            required: false,
                            message: "Please input token",
                          },
                        ]}
                      >
                        <Input className="form-input" placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={"Default start"}
                        name="defaultStart"
                        valuePropName="checked"
                      >
                        <Switch checkedChildren={"ON"} unCheckedChildren={"OFF"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={10} xxl={8} >
                  <ContainRelation>
                    <Row>
                      <WidgetControl span={24}>
                        <Relation title={"WIDGETS CONTROL"} action={false}>
                          <Table
                            rowKey={(record) => record.key}
                            bordered
                            pagination={false}
                            columns={columns}
                            dataSource={widgets}
                          />
                        </Relation>
                        <Relation handleClick={handleOpenAddFolderModal} title={"FOLDERS"} action={'Add folder'} icon={<PlusOutlined />}>
                          <Table
                            rowKey={(record) => record.id}
                            bordered
                            pagination={false}
                            columns={columncategories}
                            dataSource={categories.sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)}
                            scroll={{ y: categories.length > 5 ? 213 : null }}
                          />
                        </Relation>
                        <Relation handleClick={handleOpenAddModel3DModal} title={"MODEL3D"} action={'Add model3d'} icon={<PlusOutlined />}>
                          <Table
                            rowKey={(record) => record.id}
                            bordered
                            pagination={false}
                            columns={columnmodel3ds}
                            dataSource={model3ds}
                            scroll={{ y: model3ds.length > 5 ? 213 : null }}
                          />
                        </Relation>
                        <Relation
                          handleClick={handleOpenAddPinModal}
                          title={"PIN"}
                          action={"Add pin"}
                          icon={<PlusOutlined />}
                        >
                          <Table
                            rowKey={(record) => record.id}
                            bordered
                            pagination={false}
                            columns={columnPins}
                            dataSource={pins}
                            scroll={{ y: pins.length > 5 ? 213 : null }}
                          />
                        </Relation>
                      </WidgetControl>
                    </Row>
                  </ContainRelation>
                </Col>
              </Row>
            </Form>
            <Modal
              title="Add folder into project"
              zIndex={9999}
              open={isModalOpen}
              onOk={handleAddFolder}
              onCancel={handleCancel}
              maskClosable={false}
              className='modal-add-folder'
            >
              <Form
                name="add-folder-toproject"
                form={addFolderForm}
                layout="vertical"
                labelAlign="left"
              >
                <Form.Item
                  name="useExisting"
                  valuePropName="checked"
                  initialValue={true}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Checkbox checked={checked} onChange={onChangeCheckbox}>Use existing folder</Checkbox>
                </Form.Item>
                {
                  checked && (
                    <Form.Item
                      label={"Folders"}
                      name="folder"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select folder",
                        },
                      ]}
                    >
                      <Select
                        placeholder={
                          "Please select folder"
                        }
                        showSearch
                        allowClear={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          allcategories.filter(c => !c.projectId)
                            .map((v, i) => {
                              return (
                                <Option key={v.id} value={v.id}>
                                  {v.title}
                                </Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  )
                }
                {
                  !checked && (
                    <>
                      <Form.Item
                        name={["title"]}
                        label="Title"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="form-input"
                          placeholder="Please input title"
                        />
                      </Form.Item>
                      <Form.Item
                        name={["order"]}
                        label="Order"
                        rules={[
                          {
                            required: false
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          min={0}
                          className="form-input"
                          placeholder="Please input order number"
                        />
                      </Form.Item>
                      <Form.Item name={["desc"]} label="Description">
                        <TextArea
                          rows={4}
                          className="form-input"
                          placeholder="Please input description"
                        />
                      </Form.Item>
                      <Form.Item name={["lat"]} label="Lat" rules={[
                        {
                          required: false,
                          message: "Please input lat",
                        },
                      ]}>
                        <Input
                          type="number"
                          className="form-input"
                          placeholder="Please input lat"
                        />
                      </Form.Item>
                      <Form.Item name={["long"]} label="Long" rules={[
                        {
                          required: false,
                          message: "Please input long",
                        },
                      ]}>
                        <Input
                          type="number"
                          className="form-input"
                          placeholder="Please input long"
                        />
                      </Form.Item>
                      <Form.Item name={["height"]} label="Height" rules={[
                        {
                          required: false,
                          message: "Please input height",
                        },
                      ]}>
                        <Input
                          type="number"
                          className="form-input"
                          placeholder="Please input height"
                        />
                      </Form.Item>
                      <Form.Item name={["heading"]} label="Heading" rules={[
                        {
                          required: false,
                          message: "Please input heading",
                        },
                      ]}>
                        <Input
                          type="number"
                          className="form-input"
                          placeholder="Please input heading"
                        />
                      </Form.Item>
                      <Form.Item name={["pitch"]} label="Pitch" rules={[
                        {
                          required: false,
                          message: "Please input pitch",
                        },
                      ]}>
                        <Input
                          type="number"
                          className="form-input"
                          placeholder="Please input pitch"
                        />
                      </Form.Item>
                      <Form.Item name={["roll"]} label="Roll" rules={[
                        {
                          required: false,
                          message: "Please input roll",
                        },
                      ]}>
                        <Input
                          type="number"
                          className="form-input"
                          placeholder="Please input roll"
                        />
                      </Form.Item>
                      <Form.Item
                        label={"Icon"}
                        name="icon"
                        rules={[
                          {
                            required: true,
                            message: "Please select a icon",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder={"Please select a icon"}
                          allowClear={true}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {icons.map((v, i) => {
                            return (
                              <Option key={v.value} value={v.value}>
                                {v.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </>
                  )
                }
              </Form>
            </Modal>
            <Modal
              title="Add external model into project"
              zIndex={9999}
              open={openAddModel3d}
              onOk={handleAddModel3D}
              onCancel={handleCancelAddModel3D}
              maskClosable={false}
              className='modal-add-model-3d'
            >
              <Form
                name="add-model-3d-to-project"
                form={addModel3DForm}
                layout="vertical"
                labelAlign="left"
              >
                <Form.Item
                  name={["title"]}
                  label="Title"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    className="form-input"
                    placeholder="Please input title"
                  />
                </Form.Item>
                <Form.Item name={["desc"]} label="Description">
                  <TextArea
                    rows={4}
                    className="form-input"
                    placeholder="Please input description"
                  />
                </Form.Item>
                <Form.Item
                  name={["type"]}
                  label="Type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={[
                      { value: 'ion', label: 'ION 3DTiles' },
                      { value: 'geojson', label: 'ION Geojson' },
                      { value: 'czml', label: 'Czml' }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label={"IonAccess"}
                  name="ionAccess"
                  rules={[
                    {
                      required: false,
                      message:
                        "Please select a ionid",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={
                      "Please select a ionid"
                    }
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {ionIds.map((v, i) => {
                      return (
                        <Option key={v.id} value={v.id}>
                          {`${v.name} (${v.id})`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["sourceType"]}
                  label="SourceType"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        value: "local",
                        label: "Local",
                      },
                      {
                        value: "external",
                        label: "External",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item name={"height"} label="Height">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <GroupItem title={"Stroke"}>
                  <Col span={12}>
                    <Form.Item name={"strokewidth"} label="Witdh">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={["strokecolor"]} label="Color">
                      <input
                        style={{ width: '150px', height: '150px' }}
                        type="color"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={"strokeopacity"} label="Opacity">
                      <InputNumber max={1} min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </GroupItem>
                <GroupItem title={"Fill"}>
                  <Col span={12}>
                    <Form.Item name={["fillopacity"]} label="Opacity">
                      <InputNumber max={1} min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={"fillcolor"} label="Color">
                      <input
                        style={{ width: '150px', height: '150px' }}
                        type="color"
                      />
                    </Form.Item>
                  </Col>
                </GroupItem>
              </Form>
            </Modal>
            <ModalAddPin setPinsProject={setPins} projectId={projectId} />
          </TableContain>
        </MainContain>
      </ContentWrapper>
    </AdminDashboard>
  );
};

export default inject(
  "userStore",
  "adminStore",
  "authStore"
)(observer(EditProject));
