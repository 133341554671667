import { Button, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import styled from "styled-components";

export const SiderWrapper = styled.div`
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  bottom : 0;
  .admin-sider {
    height: 100%;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const SiderResponsive = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block !important;
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
    z-index: 9999;
    position: fixed;
    top: 72px;
    left: 0;
    bottom : 0;
    .admin-sider {
      height: 100%;
    }
  }
`

export const AdminTemplate = styled.div`
  .ant-layout-sider-children {
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  }
  .ant-menu-inline,
  .ant-menu-inline-collapsed {
    border: none;
  }
  .ant-menu-item:first-child{
    margin-top : 0px;
  }
  .ant-menu-item{
    width : 100% ;
  }
  .ant-row{
    width: 100%;
  }
  body {
    padding: 0 0 30px 0;
    background-color: #fafafa;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    font-family: open sans, Helvetica, Arial, sans-serif;
  }

  .trigger {
    color: #000;
    font-size: 22px;
    text-align: center;
    @media only screen and (max-width: 768px) {
      display: block !important;
      color: #000 !important;
    }
  }

  .trigger:hover {
    color: #1890ff;
    cursor: pointer;
  }

  .trigger-sidebar {
    color: #000;
    font-size: 22px;
    text-align: center;
  }

  .trigger-sidebar:hover {
    color: #1890ff;
    cursor: pointer;
  }

  .ant-btn {
    font-weight: 600;
  }
  .ant-form-item{
    margin: 0 0 16px;
    .ant-form-item-label{
      label{
        color : #566a7f;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
      }
    }
    .ant-form-item-control{
      &-input{
        &-content{
          .ant-input{
            padding : 7px 11px;
            border-radius: 5px;
          }
        }
        .ant-input-number-input{
          height: 34px;
          border-radius: 5px;
        }
      }
    }
  }
`;

export const ButtonTable = styled.div`
   padding : 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   color: #1890ff;
   &:hover{
    
   }
`;

export const ContainerDashboard = styled(Content)`
  background-color: #fff;
  box-sizing: border-box;
  min-height: calc(100vh - 110px);
  margin-left: ${props => props.collapsed ? `100px`: `220px`};
  margin-top: 90px;
  margin-bottom: 20px;
  margin-right: 20px;
  transition : margin-left 0.27s;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    margin-left: ${props => props.collapsed ? `20px`: `100px`};
  }
`;

export const ToolbarButton = styled(Button)`
    margin-left : 5px;
    margin-top : 2.5px;
    margin-bottom : 2.5px;
`;

export const ContentWrapper = styled(Content)`
    padding : 0 20px 20px 20px ;
    .ant-divider-horizontal{
        margin: 0 0 30px 0 ;
    }
`;

export const MainContain = styled.div`
    background-color: #fff;
    height: 100%;
`;

export const TableContain = styled(Row)`
    justify-content: space-between;
    align-items: center;
`;

export const ToolbarWrapper = styled(Row)`
    padding :20px 0;
    justify-content: space-between;
    align-items: center;
    line-height: 1em;
    .action-search {
        border: none;
        background-color: rgb(245,246,248);
        margin-top: 5px;
        border-radius: 5px;
        width: 250px;
        height: 40px;
        input{
          background:none;
        }
        &:focus , &:hover{
          border-color:rgb(245,246,248) ;
          box-shadow: none;
          --antd-wave-shadow-color :rgb(245,246,248) ;
        }
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
    }

    .action-btn {
        border-radius: 5px;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100px;
        height: 40px;
        margin-top: 5px;
        margin-bottom: 3px;
    }

    .criclebox {
        box-shadow: 20px 20px 20px 20px #0000000d;
        border-radius: 12px;
        border: 0px;
    }

    .border-space {
        border-color: white;
    }
`;

export const WidgetControl = styled(Col)`
    padding :10px 0;
    .ant-form-item{
      margin-bottom: 0px;
    }
    td.ant-table-cell{
      padding : 10px;
    }
    .action-show{
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .relation-label{
      word-break: break-word;
    }
`

export const ContainRelation = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 10px;
    border-radius: 10px;
    padding: 15px;
    margin-left: 20px;
    .relation:not(:last-child){
      padding-bottom: 20px;
    }
`