import { Checkbox, Row, Col } from 'antd';
import { inject, observer } from "mobx-react";
import { EnhancementEchniquesControlContainer, ContainerWrappers } from './style';
const Cesium = require('cesium')
const EnhancementEchniquesControl = ({ scene, projectStore }) => {
    const handleCancel = () => {
        projectStore.setCurrentToolControl('')
    }
    const onChangEnhancementEchniques = (key, value) => {
        projectStore.setViewModelEnhancementEchniques(key, value)
        if (key === 'hdr') {
            scene.highDynamicRange = value
        }
        if (key === 'fxaa') {
            scene.postProcessStages.fxaa.enabled = value
        }
        if (key === 'sun') {
            scene.sun.show  = value
        }
    }
    const checkShowWidgets = (widget) => {
        let show = false
        const widgetsControl = projectStore.widgetsControl
        if (widgetsControl) {
            show = widgetsControl[widget]
        }
        return show
    }
    return (
        <EnhancementEchniquesControlContainer>
            <Row className="close-btn" span={24}>
                <span className='group-button-skybox' onClick={() => handleCancel()}>X</span>
            </Row>
            <ContainerWrappers>
                <Row>
                    <Col>
                        <strong>Enhancement echniques</strong>
                    </Col>
                </Row>
                {
                    checkShowWidgets('widget_sunshow') && <Row className='group-button-skybox'>
                        <Col span={18}>
                            Sun show:
                        </Col>
                        <Col span={6}>
                            <Checkbox checked={projectStore.viewModelEnhancementEchniques?.sun} onChange={(e) => onChangEnhancementEchniques('sun', e.target.checked)} />
                        </Col>
                    </Row>
                }
                {
                    checkShowWidgets('widget_fxaa') && <Row className='group-button-skybox'>
                        <Col span={18}>
                            FXAA:
                        </Col>
                        <Col span={6}>
                            <Checkbox checked={projectStore.viewModelEnhancementEchniques?.fxaa} onChange={(e) => onChangEnhancementEchniques('fxaa', e.target.checked)} />
                        </Col>
                    </Row>
                }
                {
                    checkShowWidgets('widget_hdr') && <Row className='group-button-skybox'>
                        <Col span={18}>
                            HDR:
                        </Col>
                        <Col span={6}>
                            <Checkbox checked={projectStore.viewModelEnhancementEchniques?.hdr} onChange={(e) => onChangEnhancementEchniques('hdr', e.target.checked)} />
                        </Col>
                    </Row>
                }
            </ContainerWrappers>
        </EnhancementEchniquesControlContainer>
    );
};

export default inject("projectStore")(observer(EnhancementEchniquesControl));
