import UserStore from "./user.store";
import NoteStore from "./note.store";
import UIStore from "./ui.store";
import AuthStore from "./auth.store";
import ProjectStore from "./project.store";
import AdminStore from "./admin.store";

class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.noteStore = new NoteStore(this);
    this.uiStore = new UIStore(this);
    this.authStore = new AuthStore(this);
    this.projectStore = new ProjectStore(this);
    this.adminStore = new AdminStore(this);
  }
}

const rootStore = new RootStore();
export default rootStore;
