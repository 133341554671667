import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { UserRequest } from "../utils/requests";
class userStore {
  name = "John doe";

  constructor() {
    makeAutoObservable(this);
  }
  isLoading = false

  setLoadingProgress = (isLoading) => {
    this.isLoading = isLoading
  }
  currentUser = false

  setUserName = (name) => {
    this.name = name;
  };

  setCurrentUser = (data) => {
    this.currentUser = data;
  };

  clearToken = () => {
    localStorage.clear();
    sessionStorage.clear();
  }

  getCurrentUser = (history) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      UserRequest.getCurrentUser()
        .then(response => {
          this.setCurrentUser(response.data)
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(err => {
          this.clearToken();
          this.setLoadingProgress(false)
          reject(err?.response?.data)
          // message.error("Something went wrong!")
          return history('/auth/sign-in');
        })
        .finally(() => {
          this.setLoadingProgress(false)
        })
    })
  }
}

export default userStore;