import AdminDashboard from "./../../AdminDashboard/AdminDashboard";
import { Col, Input, Form, Row, Select, InputNumber } from "antd";
import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ContentWrapper, ToolbarWrapper, MainContain, TableContain, ToolbarButton } from "../../AdminDashboard/style";
import TextArea from "antd/lib/input/TextArea";
import GroupItem from "../../AdminDashboard/GroupItem";

const EditModel = ({ adminStore }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { modelId } = useParams();
  const [projects, setProjects] = useState([]);
  const [ionIds, setIonIds] = useState([]);
  const { Option } = Select;

  window.onscroll = () =>
    adminStore.onscrollSticky(window, document, "toolbar-sticky");

  useEffect(() => {
     adminStore.getAllProjects(1, 100).then((res) => {
      setProjects(res.docs);
    });
    adminStore.getIonIds().then(res=>{
      setIonIds(res)
    })
    const fetchData = async () => {
      if (modelId && modelId !== 'new') {
        await adminStore.getModelById(modelId).then(response => {
          form.setFieldsValue({
            title: response?.title,
            desc: response?.desc,
            ionAccess: response?.ionAccess,
            type: response?.type,
            sourceType: response?.sourceType,
            projectId: response?.projectId,
            height : response?.height,
            fillcolor : response?.fill?.color,
            fillopacity : response?.fill?.opacity,
            strokewidth : response?.stroke?.width,
            strokecolor : response?.stroke?.color,
            strokeopacity : response?.stroke?.opacity
          });
        })
      } else {
        form.resetFields()
      }
    }
    adminStore.setSelectedKeyMenu('model3ds?page=1&limit=10');
    fetchData()
    return () => {
      form.resetFields();
      setProjects([])
      setIonIds([])
    };
  }, [modelId]);

  const page = adminStore.currentPage;
  const limit = adminStore.currentLimit;
  const search = adminStore.searchInput;

  const backTo = () => {
    return navigate(`/admin/model3ds?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`);
  }

  const createNewModel3DS = async (payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore.createNewModel3DS(payload).then(res => {
      adminStore.setLoadingProgress(false);
      adminStore.openNotification('bottomRight', 'success', 'Created new model successfully!')
      backTo();
    })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  }
  const updateModel3DS = async (modelId, payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore.updateModel3DS(modelId, payload).then(res => {
      adminStore.setLoadingProgress(false);
      adminStore.openNotification('bottomRight', 'success', 'Updated model successfully!')
      navigate("/admin/model3ds");
    }).catch((err) => {
      adminStore.setLoadingProgress(false);
      adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
    });
  }

  const onFinish = () => {
    form.validateFields().then((values) => {
      const payload = {
        title: values?.title || "",
        desc: values?.desc || "",
        ionAccess: values?.ionAccess || null,
        type: values?.type,
        sourceType: values?.sourceType,
        projectId: values?.projectId || null,
        height : values?.height,
        fill : {
          color : values?.fillcolor,
          opacity : values?.fillopacity
        },
        stroke : {
          width : values?.strokewidth,
          color : values?.strokecolor,
          opacity : values?.strokeopacity
        }
      };
      if (modelId === "new") {
        return createNewModel3DS(payload);
      } else {
        return updateModel3DS(modelId, payload);
      }
    });
  }

  const deleteModel3DS = async () => {
    if (modelId && modelId !== "new") {
      adminStore.setLoadingProgress(true);
      await adminStore
        .deleteModel3DS(modelId)
        .then((response) => {
          adminStore.openNotification('bottomRight', 'success', 'Delete model successfully!');
          backTo()
        })
        .catch((err) => {
          adminStore.setLoadingProgress(false);
          adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
        });
    } else {
      adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
    }
  };


  return (
    <AdminDashboard>
      <ContentWrapper>
        <div id="toolbar-sticky">
          <ToolbarWrapper className="sticky-children">
            <Row justify="end">
              <ToolbarButton
                className="action-btn"
                icon={<CloseCircleOutlined />}
                onClick={backTo}
              >
                CANCEL
              </ToolbarButton>
              {modelId !== "new" && (
                <ToolbarButton
                  type="danger"
                  className="action-btn"
                  icon={<DeleteOutlined />}
                  onClick={deleteModel3DS}
                >
                  DELETE
                </ToolbarButton>
              )}
              <ToolbarButton
                type="primary"
                className="action-btn"
                onClick={onFinish}
                icon={
                  modelId === "new" ? (
                    <PlusCircleOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
              >
                {modelId === "new" ? "CREATE" : "SAVE"}
              </ToolbarButton>
            </Row>
          </ToolbarWrapper>
        </div>
        <MainContain>
          <TableContain>
            <Form
              name="edit-model3d"
              form={form}
              layout="vertical"
              labelAlign="left"
              style={{ width: '100%' }}
            >
              <Row >
                <Col span={24}>
                  <Form.Item name={["title"]} label="Title" rules={[
                    {
                      required: true,
                      message: "Please input title",
                    },
                  ]}>
                    <Input className="form-input" placeholder="Title" />
                  </Form.Item>
                </Col>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={"Project"}
                    name="projectId"
                    rules={[
                      {
                        required: false,
                        message:
                          "Please select a project",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={
                        "Please select a project"
                      }
                      allowClear={true}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {projects.map((v, i) => {
                        return (
                          <Option key={v.id} value={v.id}>
                            {v.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                </Row>
                <Col span={24}>
                  <Form.Item name={["desc"]} label="Description" rules={[
                    {
                      required: false,
                      message: "Please input description",
                    },
                  ]}>
                    <TextArea rows={4} className="form-input" placeholder="Please input description" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name={["type"]}
                    label="Type"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        { value: 'ion', label: 'ION 3DTiles' },
                        { value: 'geojson', label: 'ION Geojson' },
                        { value: 'czml', label: 'Czml' }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={"IonAccess"}
                    name="ionAccess"
                    rules={[
                      {
                        required: false,
                        message:
                          "Please select a ionid",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={
                        "Please select a ionid"
                      }
                      allowClear={true}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {ionIds.map((v, i) => {
                        return (
                          <Option key={v.id} value={v.id}>
                            {`${v.name} (${v.id})`}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                </Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name={["sourceType"]}
                    label="SourceType"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          value: "local",
                          label: "Local",
                        },
                        {
                          value: "external",
                          label: "External",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name={"height"} label="Height">
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <GroupItem title={"Stroke"}>
                  <Col span={12}>
                    <Form.Item name={"strokewidth"} label="Witdh">
                    <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name={["strokecolor"]} label="color">
                    <input
                    style={{ width: '150px', height: '150px' }}
                    type="color"
                    />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={"strokeopacity"} label="Opacity">
                    <InputNumber max={1} min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </GroupItem>
                <GroupItem title={"Fill"}>
                  <Col span={24}>
                    <Form.Item name={"fillcolor"} label="Color">
                    <input
                    style={{ width: '150px', height: '150px' }}
                    type="color"
                    />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={["fillopacity"]} label="Opacity">
                    <InputNumber max={1} min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </GroupItem>
              </Row>
            </Form>
          </TableContain>
        </MainContain>
      </ContentWrapper>
    </AdminDashboard>
  );
}

export default inject("adminStore")(observer(EditModel));