import React from "react";
import PropTypes from 'prop-types';
import { Button, Col, Row } from "antd";
import {
  CaretUpOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

import './style.scss';

const NavigationArrow = ({
  leftNavigate,
  rightNavigate,
  forwardNavigate,
  backwardNavigate,
}) => {
  return (
    <div className="navigation-wrapper">
      <Row>
        <Col
          span={24}
          justify="center"
          align="center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            type="primary"
            shape="circle"
            icon={<CaretUpOutlined />}
            size={"large"}
            className="navigation-btn"
            onTouchStart={forwardNavigate.enable}
            onTouchEnd={forwardNavigate.disable}
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            type="primary"
            shape="circle"
            icon={<CaretLeftOutlined />}
            size={"large"}
            className="navigation-btn"
            onTouchStart={leftNavigate.enable}
            onTouchEnd={leftNavigate.disable}
          />
          <Button
            type="primary"
            shape="circle"
            icon={<CaretRightOutlined />}
            size={"large"}
            className="navigation-btn"
            onTouchStart={rightNavigate.enable}
            onTouchEnd={rightNavigate.disable}
          />
        </Col>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            shape="circle"
            icon={<CaretDownOutlined />}
            size={"large"}
            className="navigation-btn"
            onTouchStart={backwardNavigate.enable}
            onTouchEnd={backwardNavigate.disable}
          />
        </Col>
      </Row>
    </div>
  );
};

NavigationArrow.propTypes = {
  leftNavigate: PropTypes.func,
  rightNavigate: PropTypes.func,
  forwardNavigate: PropTypes.func,
  backwardNavigate: PropTypes.func,
  upNavigate: PropTypes.func,
  downNavigate: PropTypes.func,
};

export default NavigationArrow;
