import { useEffect } from "react";
import { AccountWrapper, HeaderWrapper, MenuIconWrapper } from "./style";

import {
  Row,
  Col,
  Dropdown,
  Menu,
  Avatar
} from "antd";

import {
  UserOutlined,
  LogoutOutlined,
  MenuOutlined,
  CaretDownOutlined,
  SettingOutlined
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { useMediaQuery } from "react-responsive";

const Header = ({ adminStore, userStore, collapsed }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
  const toggle = () => adminStore.setCollapsed(!adminStore.collapsed);

  const toLogin = () => {
    if (localStorage.getItem('jwt'))
      localStorage.removeItem('jwt');
    if (sessionStorage.getItem('jwt'))
      sessionStorage.removeItem('jwt');
    userStore.setCurrentUser(false);
    navigate('/auth/sign-in');
  };

  const menu = (
    <Menu>
      <Menu.Item key="account">
        <span style={{ marginLeft: 10 }}>
          <UserOutlined />
          <span style={{ marginLeft: 5, marginRight: 10 }}>
            Account
          </span>
        </span>
      </Menu.Item>
      <Menu.Item key="setting">
        <span style={{ marginLeft: 10 }}>
          <SettingOutlined />
          <span style={{ marginLeft: 5, marginRight: 10 }}>
            Setting
          </span>
        </span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <span style={{ marginLeft: 10 }}>
          <LogoutOutlined />
          <span onClick={() => toLogin()} style={{ marginLeft: 5, marginRight: 10 }}>
            Log Out
          </span>
        </span>
      </Menu.Item>
      <div className="ant-dropdown-menu-arrow-up"></div>
    </Menu>
  );

  useEffect(() => window.scrollTo(0, 0));
  return (
    <HeaderWrapper collapsed={collapsed ? 1 : 0}>
      <Row justify='space-between' gutter={[24, 0]} >
        <MenuIconWrapper>
          <span
            className="trigger"
            onClick={toggle}
            style={{ display: collapsed ? "block" : "none" }}
          >
            <span><MenuOutlined /></span>
          </span>
        </MenuIconWrapper>
        <AccountWrapper >
          <Dropdown
            overlay={(item) => (
              <div className="account-dropdown">
                {menu}
              </div>
            )}
            trigger={["click"]}
          >
            <span>
              {
                userStore?.currentUser?.avatar
                  ?
                  <Avatar src={userStore?.currentUser?.avatar} shape='circle' size={32}  ></Avatar>
                  :
                  <Avatar icon={<UserOutlined />} shape='circle' size={32}  ></Avatar>
              }
              {!isMobile && <span style={{ margin: 6, fontSize: "16px" }}>{userStore?.currentUser?.username}</span>}
              {!isMobile && <span><CaretDownOutlined /></span>}
            </span>
          </Dropdown>
        </AccountWrapper>
      </Row>
    </HeaderWrapper>
  );
}

export default inject("userStore", "adminStore")(observer(Header));
