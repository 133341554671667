import { SaveOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, InputNumber, Row, Slider, Tooltip } from 'antd';
import { Math as CesiumMath } from 'cesium';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ButtonCustom, ButtonWrappers, ContainerWrappers, OptimizeContainer } from './style';

const OptimizeControl = ({ projectStore, viewer }) => {
    const onChange = value => {
        projectStore.setResolutionValue(value)
    }

    const onChangeCheck = (e) => {
        projectStore.setBrowserRecommendedResolution(e.target.checked)
    }

    const handleCancel = () => {
        projectStore.setCurrentToolControl('')
    }

    return (
        <OptimizeContainer>
            <Row className="close-btn" span={24}>
                <Tooltip zIndex={9999} title='Close' placement="left">
                    <span onClick={() => handleCancel()}>X</span>
                </Tooltip>
            </Row>
            <ContainerWrappers>
                <Row>
                    <Col>
                        <strong>Optimize</strong>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col >
                        Device optimized:
                    </Col>
                    <Col >
                        <Checkbox checked={projectStore.browserRecommendedResolution} onChange={onChangeCheck} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Render resolution:
                    </Col>
                </Row>
                <Row>
                    <Col span={16}>
                        <Slider
                            min={0}
                            max={2}
                            onChange={onChange}
                            value={typeof projectStore.renderResolutionValue === 'number' ? projectStore.renderResolutionValue : 0.1}
                            step={0.1}
                        />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            style={{ width: 'auto' }}
                            min={0}
                            max={2}
                            value={projectStore.renderResolutionValue}
                            onChange={onChange}
                            step={0.1}
                        />
                    </Col>
                </Row>
                <ButtonWrappers justify='end'>
                    <Col>
                        <ButtonCustom
                            icon={<SaveOutlined />}
                            onClick={handleCancel}>
                            Cancel
                        </ButtonCustom>
                    </Col>
                    <Col>
                        <ButtonCustom
                            icon={<SaveOutlined />}
                            type="primary"
                            onClick={() => projectStore.setCurrentToolControl('')}>
                            Save
                        </ButtonCustom>
                    </Col>
                </ButtonWrappers>
            </ContainerWrappers>
        </OptimizeContainer>
    )
}
export default inject('projectStore', 'projectStore')(observer(OptimizeControl))