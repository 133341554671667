import { Col, Form, Input, InputNumber, Modal } from "antd";
import { useEffect, useState } from "react";
import GroupItem from "../../AdminDashboard/GroupItem";
import TextArea from "antd/lib/input/TextArea";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import UploadImage from "../../../components/elements/UploadImage/UploadImage";

const ModalAddPin = ({ adminStore, setPinsProject, projectId, setPinsFolder, folderId, setPinsSubFolder, subFolderId, setPinsSubSubFolder, subSubFolderId }) => {
     const [imageUrl, setImageUrl] = useState(null)
     const [addPinForm] = Form.useForm();
     const location = useLocation();
     let pathname = location.pathname;

     useEffect(() => {
          addPinForm.setFieldsValue({
               icon: "https://s3.eu-west-3.amazonaws.com/bacha.zenpix/Icons/icons8-home-address-96.png"
          })
          return () => {
               addPinForm.resetFields();
               setImageUrl(null);
          }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [adminStore.openPinModal])

     const cancelAddPin = () => {
          adminStore.setOpenPinModal(false);
          addPinForm.resetFields();
     };

     const handleCreateNewPin = () => {
          addPinForm.validateFields().then(async response => {
               let payload = {
                    title: response?.title,
                    docId: pathname.includes("projects") ? projectId : pathname.includes("/folders/") ? folderId : pathname.includes("/subfolders/") ? subFolderId : pathname.includes("sub-subfolders") ? subSubFolderId : "",
                    docModel: pathname.includes("projects") ? "Project" : pathname.includes("/folders/") ? "Category" : pathname.includes("/subfolders/") ? "SubCategory" : pathname.includes("sub-subfolders") ? "SubCategoryChecklist" : "",
                    lat: response?.lat || 0,
                    long: response?.long || 0,
                    height: response?.height || 0,
                    icon: response?.icon || '',
                    orientation: {
                         heading: response?.heading || 0,
                         pitch: response?.pitch || 0,
                         roll: response?.roll || 0,
                    },
                    properties: {
                         title: response?.propertiesTitle || "",
                         description: response?.propertiesDescription || "",
                         image: imageUrl || ""
                    }
               };
               adminStore.setLoadingProgress(true);
               await adminStore.createNewPin(payload).then((response) => {
                    if (pathname.includes("projects")) {
                         setPinsProject(pins => [...pins, response]);
                    }
                    if (pathname.includes("/folders/")) {
                         setPinsFolder(pins => [...pins, response])
                    }
                    if (pathname.includes("/subfolders/")) {
                         setPinsSubFolder(pins => [...pins, response])
                    }
                    if (pathname.includes("sub-subfolders")) {
                         setPinsSubSubFolder(pins => [...pins, response])
                    }
                    cancelAddPin();
                    adminStore.setLoadingProgress(false);
                    adminStore.openNotification('bottomRight', 'success', 'Create new pin successfully!');
               })
                    .catch((err) => {
                         adminStore.setLoadingProgress(false);
                         adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
                    });
          })
     };

     return (
          <Modal
               title={`Add pin into ${pathname.includes("projects") ? "project" : pathname.includes("/folders/") ? "folder" : pathname.includes("/subfolders/") ? "sub folder" : pathname.includes("sub-subfolders") ? "sub-subfolder" : ""}`}
               zIndex={9999}
               open={adminStore.openPinModal}
               onOk={handleCreateNewPin}
               onCancel={cancelAddPin}
               maskClosable={false}
               className='modal-add-folder'
          >
               <Form
                    name="add-pin-to-project"
                    form={addPinForm}
                    layout="vertical"
                    labelAlign="left"
               >
                    <Form.Item
                         name={["title"]}
                         label="Title"
                         rules={[
                              {
                                   required: true,
                              },
                         ]}
                    >
                         <Input
                              className="form-input"
                              placeholder="Please input title"
                         />
                    </Form.Item>
                    <Form.Item name={"icon"} label="Icon"
                         rules={[
                              {
                                   required: true,
                                   message: "Please input pin url",
                              },
                         ]}
                    >
                         <Input />
                    </Form.Item>
                    <GroupItem title={"orientation"}>
                         <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                              <Form.Item name={"heading"} label="Heading">
                                   <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                         </Col>
                         <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                              <Form.Item name={["pitch"]} label="Pitch">
                                   <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                         </Col>
                         <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                              <Form.Item name={"roll"} label="Roll">
                                   <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                         </Col>
                    </GroupItem>
                    <Form.Item name={["lat"]} label="Lat" rules={[
                         {
                              required: false,
                              message: "Please input lat",
                         },
                    ]}>
                         <Input
                              type="number"
                              className="form-input"
                              placeholder="Please input lat"
                         />
                    </Form.Item>
                    <Form.Item name={["long"]} label="Long" rules={[
                         {
                              required: false,
                              message: "Please input long",
                         },
                    ]}>
                         <Input
                              type="number"
                              className="form-input"
                              placeholder="Please input long"
                         />
                    </Form.Item>
                    <Form.Item name={["height"]} label="Height" rules={[
                         {
                              required: false,
                              message: "Please input height",
                         },
                    ]}>
                         <Input
                              type="number"
                              className="form-input"
                              placeholder="Please input height"
                         />
                    </Form.Item>
                    <GroupItem title={"properties"}>
                         <Col span={24}>
                              <Form.Item
                                   name={["propertiesTitle"]}
                                   label="Title"
                              >
                                   <Input
                                        className="form-input"
                                        placeholder="Please input properties title"
                                   />
                              </Form.Item>
                         </Col>
                         <Col span={24}>
                              <Form.Item
                                   name={["propertiesDescription"]}
                                   label="Description"
                              >
                                   <TextArea rows={4} className="form-input" placeholder="Please input properties description" />
                              </Form.Item>
                         </Col>
                         <Col span={24}>
                              <UploadImage imageUrl={imageUrl} setImageUrl={setImageUrl} propertiesName={'propertiesImage'}/>
                         </Col>
                    </GroupItem>
               </Form>
          </Modal>
     )
}

export default inject("userStore", "adminStore", "authStore")(observer(ModalAddPin));