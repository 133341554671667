import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { BreadWrapper } from "./style";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";

const BreadcrumbComponent = ({ pathname, collapsed, adminStore }) => {
     const paths = pathname.split('/')
     const [project, setProject] = useState(null);
     const [folder, setFolder] = useState(null);
     const [projectDetail, setProjectDetail] = useState(null);
     const [folderDetail, setFolderDetail] = useState(null);
     const [subFolderDetail, setSubFolderDetail] = useState(null);
     const [subSubFolderDetail, setSubSubFolderDetail] = useState(null);

     useEffect(() => {
          setProject(JSON.parse(localStorage.getItem("project")));
          setFolder(JSON.parse(localStorage.getItem("folder")));
          setProjectDetail(JSON.parse(localStorage.getItem("projectDetail")));
          setFolderDetail(JSON.parse(localStorage.getItem("folderDetail")));
          setSubFolderDetail(JSON.parse(localStorage.getItem("subFolderDetail")));
          setSubSubFolderDetail(JSON.parse(localStorage.getItem("sub-subfolder")));
     }, [])

     if (projectDetail) {
          paths.splice(1, 1, projectDetail?.name)
          localStorage.removeItem("projectDetail")
     }

     if (folderDetail) {
          if (project) {
               paths.splice(1, 1, folderDetail?.project?.name, folderDetail?.title)
               localStorage.removeItem("folderDetail")
          } else {
               paths.splice(1, 1, folderDetail?.title)
               localStorage.removeItem("folderDetail")
          }
     }

     if (subFolderDetail) {
          if (project) {
               paths.splice(1, 1, project?.name, subFolderDetail?.category?.title, subFolderDetail?.title)
               localStorage.removeItem("subFolderDetail")
               localStorage.removeItem("project")
          } else if (folder) {
               paths.splice(1, 1, folder?.title, subFolderDetail?.title)
               localStorage.removeItem("subFolderDetail")
               localStorage.removeItem("folder")
          }
          else {
               paths.splice(1, 1, subFolderDetail?.title)
               localStorage.removeItem("subFolderDetail")
          }
     }

     if (subSubFolderDetail) {
          paths.splice(1, 1, subSubFolderDetail?.title);
          localStorage.removeItem("sub-subfolder");
     }

     const toHome = (redirect) => {
          if (redirect !== '/admin') return
          adminStore.setSelectedKeyMenu('');
     }

     return (
          <BreadWrapper collapsed={collapsed ? 1 : 0} >
               <Breadcrumb>
                    {
                         paths && paths.map((element, index) => {
                              const initialPath = "/";
                              const reducePath = "";
                              const page = adminStore.currentPage;
                              const limit = adminStore.currentLimit;
                              const search = adminStore.searchInput;
                              const redirect = paths.reduce(
                                   (accumulator, current, k) => {
                                        const text =
                                             accumulator +
                                             (index >= k ? initialPath + current : "");
                                        return text;
                                   },
                                   reducePath
                              );
                              return (
                                   <Breadcrumb.Item key={element} style={{ textTransform: "capitalize" }}>
                                        {
                                             projectDetail ?
                                                  <NavLink onClick={() => toHome(redirect)}
                                                       to={redirect === "/admin" ? redirect :
                                                            redirect === `/admin/${projectDetail?.name}` ? `/admin/projects/${projectDetail?.id}` :
                                                                 redirect === `/admin/${projectDetail?.name}/model3ds` ? `/admin/model3ds?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}` :
                                                                      `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                       {element === 'admin' ? <HomeOutlined /> : element}
                                                  </NavLink>
                                                  :
                                                  folderDetail ? project ?
                                                       <NavLink onClick={() => toHome(redirect)}
                                                            to={redirect === "/admin" ? redirect :
                                                                 redirect === `/admin/${folderDetail?.project?.name}` ? `/admin/projects/${folderDetail?.project?.id}` :
                                                                      redirect === `/admin/${folderDetail?.project?.name}/${folderDetail?.title}` ? `/admin/folders/${folderDetail?.id}` :
                                                                           `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                            {element === 'admin' ? <HomeOutlined /> : element}
                                                       </NavLink>
                                                       : <NavLink onClick={() => toHome(redirect)}
                                                            to={redirect === "/admin" ? redirect :
                                                                 redirect === `/admin/${folderDetail?.title}` ? `/admin/folders/${folderDetail?.id}` :
                                                                      `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                            {element === 'admin' ? <HomeOutlined /> : element}
                                                       </NavLink>
                                                       :
                                                       subFolderDetail ? project ?
                                                            <NavLink onClick={() => toHome(redirect)}
                                                                 to={redirect === "/admin" ? redirect :
                                                                      redirect === `/admin/${project?.name}` ? `/admin/projects/${project?.id}` :
                                                                           redirect === `/admin/${project?.name}/${subFolderDetail?.category?.title}` ? `/admin/folders/${subFolderDetail?.categoryId}` :
                                                                                redirect === `/admin/${project?.name}/${subFolderDetail?.category?.title}/${subFolderDetail?.title}` ? `/admin/subfolders/${subFolderDetail?.id}` :
                                                                                     `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                                 {element === 'admin' ? <HomeOutlined /> : element}
                                                            </NavLink>
                                                            : folder ? <NavLink onClick={() => toHome(redirect)}
                                                                 to={redirect === "/admin" ? redirect :
                                                                      redirect === `/admin/${folder?.title}` ? `/admin/folders/${subFolderDetail?.categoryId}` :
                                                                           redirect === `/admin/${folder?.title}/${subFolderDetail?.title}` ? `/admin/subfolders/${subFolderDetail?.id}` :
                                                                                `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                                 {element === 'admin' ? <HomeOutlined /> : element}
                                                            </NavLink>
                                                                 : <NavLink onClick={() => toHome(redirect)}
                                                                      to={redirect === "/admin" ? redirect :
                                                                           redirect === `/admin/${subFolderDetail?.title}` ? `/admin/subfolders/${subFolderDetail?.id}` :
                                                                                `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                                      {element === 'admin' ? <HomeOutlined /> : element}
                                                                 </NavLink>
                                                            : subSubFolderDetail ? <NavLink onClick={() => toHome(redirect)}
                                                                 to={redirect === "/admin" ? redirect :
                                                                      redirect === `/admin/${subSubFolderDetail?.title}` ? `/admin/sub-subfolders/${subSubFolderDetail?.id}` :
                                                                           `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                                 {element === 'admin' ? <HomeOutlined /> : element}
                                                            </NavLink>

                                                                 : <NavLink onClick={() => toHome(redirect)}
                                                                      to={redirect === "/admin" ? redirect : `${redirect}?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`}>
                                                                      {element === 'admin' ? <HomeOutlined /> : element}
                                                                 </NavLink>
                                        }
                                   </Breadcrumb.Item>
                              )
                         })
                    }
               </Breadcrumb>
          </BreadWrapper>
     )
}

export default inject('adminStore')(observer(BreadcrumbComponent));
