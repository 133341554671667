import { Radio, Row } from 'antd';
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { SkyBoxContainer } from './style';
const Cesium = require('cesium')
const SkyBoxControl = ({ viewer, scene, projectStore }) => {
    const [isShowSkyBox, setIsShowSkyBox] = useState(true)
    const handleCancel = () => {
        projectStore.setCurrentToolControl('')
    }
    scene.globe.enableLighting = true;
    const scratchIcrfToFixed = new Cesium.Matrix3();
    const scratchMoonPosition = new Cesium.Cartesian3();
    const scratchMoonDirection = new Cesium.Cartesian3();
    function getMoonDirection(result) {
        result = Cesium.defined(result) ? result : new Cesium.Cartesian3();
        const icrfToFixed = scratchIcrfToFixed;
        const date = viewer.clock.currentTime;
        if (
            !Cesium.defined(
                Cesium.Transforms.computeIcrfToFixedMatrix(date, icrfToFixed)
            )
        ) {
            Cesium.Transforms.computeTemeToPseudoFixedMatrix(date, icrfToFixed);
        }
        const moonPosition = Cesium.Simon1994PlanetaryPositions.computeMoonPositionInEarthInertialFrame(
            date,
            scratchMoonPosition
        );
        Cesium.Matrix3.multiplyByVector(
            icrfToFixed,
            moonPosition,
            moonPosition
        );
        const moonDirection = Cesium.Cartesian3.normalize(
            moonPosition,
            scratchMoonDirection
        );
        return Cesium.Cartesian3.negate(moonDirection, result);
    }

    const directionalLight = new Cesium.DirectionalLight({
        direction: new Cesium.Cartesian3(
            0.2454278300540191,
            0.8842635425193919,
            0.39729481195458805
        ),
    });

    const flashlight = new Cesium.DirectionalLight({
        direction: scene.camera.directionWC, // Updated every frame
    });

    const moonLight = new Cesium.DirectionalLight({
        direction: getMoonDirection(), // Updated every frame
        color: new Cesium.Color(0.9, 0.925, 1.0),
        intensity: 0.5,
    });

    const sunLight = new Cesium.SunLight();

    const customColorLight = new Cesium.DirectionalLight({
        direction: new Cesium.Cartesian3(
            -0.2454278300540191,
            0.8842635425193919,
            0.39729481195458805
        ),
        color: Cesium.Color.fromCssColorString("#deca7c"),
    });
    function setTime(iso8601) {
        const currentTime = Cesium.JulianDate.fromIso8601(iso8601);
        const endTime = Cesium.JulianDate.addDays(
            currentTime,
            2,
            new Cesium.JulianDate()
        );

        viewer.clock.currentTime = currentTime;
        viewer.timeline.zoomTo(currentTime, endTime);
    }

    function reset() {
        scene.light = sunLight;
        scene.globe.dynamicAtmosphereLighting = true;
        scene.globe.dynamicAtmosphereLightingFromSun = false;
        const data = new Date().toISOString()
        setTime(data);
    }

    useEffect(() => {
        if (isShowSkyBox) {
            if (projectStore.currSkyBox === 'fixedlighting') {
                reset();
                scene.light = directionalLight;
            } else if (projectStore.currSkyBox === 'flashlight') {
                reset();
                scene.light = flashlight;
                scene.globe.dynamicAtmosphereLighting = false;
            } else if (projectStore.currSkyBox === 'moonlight') {
                reset();
                scene.light = moonLight;
                scene.globe.dynamicAtmosphereLightingFromSun = true;
                let today = new Date();
                today.setHours(20);
                today.setMinutes(0);
                today.setSeconds(0);
                const time = today.getTime();
                setTime(new Date(time).toISOString());
                scene.light.direction = getMoonDirection(scene.light.direction);
            } else if (projectStore.currSkyBox === 'sunlight') {
                reset();
            } else if (projectStore.currSkyBox === 'customcolor') {
                reset();
                scene.light = customColorLight;
            }

        }
    }, [projectStore.currSkyBox, isShowSkyBox])

    // useEffect(() => {
    //     const maxHeight = projectStore.cameraPosition?.height || 0;
    //     if (maxHeight > 230000) {
    //         setIsShowSkyBox(false)
    //         reset();
    //     } else {
    //         setIsShowSkyBox(true)
    //     }
    // }, [projectStore.cameraPosition?.height])
    return (
        <SkyBoxContainer>
            <Row className="close-btn" span={24}>
                <Radio.Group optionType="button" buttonStyle="solid" className='group-button-skybox' value={projectStore.currSkyBox} onChange={e => projectStore.setCurrSkyBox(e.target.value)}>
                    <Radio.Button value="fixedlighting">Fixed lighting</Radio.Button>
                    <Radio.Button value="flashlight">Flashlight</Radio.Button>
                    <Radio.Button value="moonlight">Moonlight</Radio.Button>
                    <Radio.Button value="sunlight">Sunlight</Radio.Button>
                    <Radio.Button value="customcolor">Custom color</Radio.Button>
                </Radio.Group>
                <span className='group-button-skybox' onClick={() => handleCancel()}>X</span>
            </Row>
        </SkyBoxContainer>
    );
};

export default inject("projectStore")(observer(SkyBoxControl));
