import 'antd/dist/antd.min.css';
import { Ion } from 'cesium';
import { Provider } from 'mobx-react';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import './App.scss';
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import ProjectDetail from "./pages/ProjectDetail";
import store from './stores';
import { cesiumToken } from './utils/config';
import ProtectedRoute from './pages/ProtectedRoute';
// import SignIn from './pages/AuthenticationPage/SignIn';
// import SignUp from './pages/AuthenticationPage/SignUp';
// import ResetPassword from './pages/AuthenticationPage/ResetPassword';
// import ConfirmPassword from './pages/AuthenticationPage/ConfirmPassword';
// import ConfirmAccount from './pages/AuthenticationPage/ConfirmAccount/ConfirmAccount';
// import PageNotFound from './pages/AuthenticationPage/PageNotFound';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import Project3D from './pages/AdminPage/Project3D';
import Categories from './pages/AdminPage/Categories/Categories';
import EditCategory from './pages/AdminPage/Categories/EditCategory';
import { useEffect } from 'react';
import EditProject from './pages/AdminPage/Project3D/EditProject';
import Model3D from './pages/AdminPage/Model3D';
import EditModel from './pages/AdminPage/Model3D/EditModel';
import PointOfInterest from './pages/AdminPage/PointOfInterest';
import EditPointOfInterest from './pages/AdminPage/PointOfInterest/EditPointOfInterest';
import EditSubCategory from './pages/AdminPage/SubCategory/EditSubCategory';
import SubCategory from './pages/AdminPage/SubCategory/SubCategory';
import Pin from './pages/AdminPage/Pin';
import EditPin from './pages/AdminPage/Pin/EditPin';
import AuthenticationPage from './pages/AuthenticationPage';
// import NewProjectDetail from './pages/ProjectDetail/NewProjectDetail';

const userStore = store.userStore;

function App() {
  Ion.defaultAccessToken = cesiumToken
  useEffect(() => {
    let fetchData = async () => {
      await userStore.getCurrentUser().then(response => {
        userStore.setCurrentUser(response)
      })
        .catch(err => {
          userStore.setLoadingProgress(false)
          toLogin();
        })
    }
    if (!userStore.currentUser) {
      fetchData()
    }
  }, [])

  const toLogin = () => {
    Navigate('/auth/sign-in');
  };

  return (
    <div className="App">
      <Provider  {...store}>
        <Router history={Navigate} >
          <Routes >
            <Route path="/auth/:authPage" element={<AuthenticationPage />} />
            {/* <Route path="/auth/sign-up" element={<SignUp />} />
            <Route path="/auth/sign-in" element={<SignIn />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/auth/confirm-password" element={<ConfirmPassword />} />
            <Route path="/auth/confirm-account" element={<ConfirmAccount />} /> */}
            {/* <Route path="/404" exact element={<PageNotFound />} /> */}
            {/* <Route path="/" element={<ProjectDetail />} />
            {/* <Route path="/home" element={<ProtectedRoute><NewProjectDetail /></ProtectedRoute>} /> */}
            <Route path="/home" element={<ProtectedRoute><ProjectDetail /></ProtectedRoute>} />
            <Route path="/admin" element={<ProtectedRoute meta={{ roles: ["admin"] }}><AdminDashboard /></ProtectedRoute>} />
            <Route path="/admin/folders" element={<ProtectedRoute meta={{ roles: ["admin"] }}><Categories /></ProtectedRoute>} />
            <Route path="/admin/folders/:categoryId" element={<ProtectedRoute meta={{ roles: ["admin"] }}><EditCategory /></ProtectedRoute>} />
            <Route path="/admin/subfolders" element={<ProtectedRoute meta={{ roles: ["admin"] }}><SubCategory /></ProtectedRoute>} />
            <Route path="/admin/subfolders/:subcategoryId" element={<ProtectedRoute meta={{ roles: ["admin"] }}><EditSubCategory /></ProtectedRoute>} />
            <Route path="/admin/sub-subfolders" element={<ProtectedRoute meta={{ roles: ["admin"] }}><PointOfInterest /></ProtectedRoute>} />
            <Route path="/admin/sub-subfolders/:pointOfInterestId" element={<ProtectedRoute meta={{ roles: ["admin"] }}><EditPointOfInterest /></ProtectedRoute>} />
            <Route path="/admin/projects" element={<ProtectedRoute meta={{ roles: ["admin"] }}><Project3D /></ProtectedRoute>} />
            <Route path="/admin/projects/:projectId" element={<ProtectedRoute meta={{ roles: ["admin"] }}><EditProject /></ProtectedRoute>} />
            <Route path="/admin/model3ds" element={<ProtectedRoute meta={{ roles: ["admin"] }}><Model3D /></ProtectedRoute>} />
            <Route path="/admin/model3ds/:modelId" element={<ProtectedRoute meta={{ roles: ["admin"] }}><EditModel /></ProtectedRoute>} />
            <Route path="/admin/pins" element={<ProtectedRoute meta={{ roles: ["admin"] }}><Pin /></ProtectedRoute>} />
            <Route path="/admin/pins/:pinId" element={<ProtectedRoute meta={{ roles: ["admin"] }}><EditPin /></ProtectedRoute>} />
            <Route path="/project" element={<Home />} />
            <Route path="/project/:projectId" element={<ProjectDetail />} />
            <Route path="/profile" element={<Profile />} />
            {/* <Route path="/project" element={<ProjectDetail />} /> */}
            <Route path="*" element={<Navigate to="/home" replace />} />
            {/* <Route path="*" element={<SignIn />} /> */}
            {/* <Route path="*" element={<PageNotFound />} /> */}
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
